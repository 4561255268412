/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { useHistory, Link, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
//Custom libraries
import {
  CommerceEnvironment,
  KEY_CODES,
  SEARCHTERM,
} from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT } from "../../../configs/catalog";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";
//UI
import {
  StyledTextField,
  StyledIconButton,
  StyledMenuItem,
  StyledSearchBar,
  StyledMenuTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { InputAdornment, ClickAwayListener } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AdobeService from "../../../_foundation/adobeService/adobeService";

const SearchBar: React.FC<SearchBarProps> = ({
  showSearchBar,
  openSearchBar,
  closeSearchBar,
}) => {
  const widgetName = getDisplayName(SearchBar);
  const contractId = useSelector(currentContractIdSelector);
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<
    Array<Object>
  >([]);
  const [categorySuggestions, setCategorySuggestions] = React.useState<
    Array<Object>
  >([]);
  const [brandSuggestions, setBrandSuggestions] = React.useState<Array<Object>>(
    []
  );
  const [productSuggestions, setProductSuggestions] = React.useState<
    Array<Object>
  >([]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const searchField = t("SearchBar.SearchField");
  const keywordTitle = t("SearchBar.KeywordTitle");
  const categoryTitle = t("SearchBar.CategoryTitle");
  const brandTitle = t("SearchBar.BrandTitle");
  const productTitle = t("SearchBar.ProductTitle");
  const [input, setInput] = React.useState("");
  const [nameList, setNameList] = React.useState<Array<string>>([]);
  const [index, setIndex] = React.useState(0);
  let nameListIndex = 1;
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const [showKeywords, setShowKeywords] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);
  const [showProducts, setShowProducts] = React.useState(false);

  const [categories, setCategories] = React.useState<Array<string>>([]);
  const [brands, setBrands] = React.useState<Array<string>>([]);
  const [categoriesUrl, setCategoriesUrl] = React.useState<Map<any, any>>(
    () => new Map()
  );

  const [inputDisabled, setinputDisabled] = React.useState(false);
  const currentVehicle = localStorage.getItem("currentVehicle")
    ? localStorage.getItem("currentVehicle")
    : "";

  const clearSuggestions = () => {
    setIndex(0);
    setKeywordSuggestions([]);
    setCategorySuggestions([]);
    setBrandSuggestions([]);
    setProductSuggestions([]);
    setShowKeywords(false);
    setShowCategories(false);
    setShowBrands(false);
    setShowProducts(false);
  };

  const clearSuggestionsAndUpdateInputField = (str: string) => {
    sessionStorage.removeItem("selectedPage");
    sessionStorage.removeItem("selectedPageOffSet");
    clearSuggestions();
    str = callRegex(str);
    setInput(str);
    setShowSearchBar(!showSearchBar);
  };

  const clearSuggestionsAndInputField = () => {
    clearKeywords();
    clearSuggestions();
    setInput("");
  };

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(""));
  };

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list));
  };
  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const brandPayloadBase: any = {
    widget: widgetName,
    query: { pageSize: "300" },
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const xPayloadBase: any = {
    widget: widgetName,
    query: { searchType: "100", pageSize: "5" },
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  if (currentVehicle != "" && mySite.storeCfg.userData["ford.YMMFilter"]) {
    xPayloadBase["query"] = {
      ...xPayloadBase["query"],
      "_wcf.search.filter.expr":
        "facets." +
        mySite.storeCfg.userData["ford.YMMFilter"] +
        '.value.raw:"' +
        currentVehicle +
        '"',
    };
  }

  useEffect(() => {
    document.querySelector("body")?.classList.add("quick-search-open");
    setTimeout(() => {
      document.getElementById("global-search")?.focus();
    }, 300);
    if (mySite && contractId) {
      const catalogId = mySite?.catalogID;
      const parameters: any = {
        responseFormat: "application/json",
        suggestType: ["Category", "Brand"],

        contractId: contractId,
        catalogId: catalogId,
        ...brandPayloadBase,
      };
      siteContentService
        .findSuggestionsUsingGET(parameters)
        .then((res) => {
          if (res.status === OK) {
            const categoriesResponse = res?.data?.suggestionView?.find(
              (i) => i.identifier === "Category"
            )?.entry;
            const brandsResponse = res?.data?.suggestionView?.find(
              (i) => i.identifier === "Brand"
            )?.entry;
            generateCategoriesList(categoriesResponse);
            generateCategoriesURL(categoriesResponse);
            generateBrandsList(brandsResponse);
            setinputDisabled(false);
          }
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, t, contractId]);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCategoriesList = (categoriesResponse: any[]) => {
    const lists: string[] = [];
    for (
      let i = 0;
      i < categoriesResponse.length &&
      categoriesResponse[i].shortDescription != "Ford_External_Link";
      i++
    ) {
      lists.push(categoriesResponse[i].fullPath);
    }
    setCategories(lists);
  };
  const generateBrandsList = (brandsResponse: any[]) => {
    const lists: string[] = [];
    if (brandsResponse) {
      for (let i = 0; i < brandsResponse.length; i++) {
        lists.push(brandsResponse[i].name);
      }
      setBrands(lists);
    }
  };

  const generateCategoriesURL = (categoriesResponse: any[]) => {
    const categoriesUrl = new Map();
    for (let i = 0; i < categoriesResponse.length; i++) {
      let url = categoriesResponse[i].seo ? categoriesResponse[i].seo.href : "";
      categoriesUrl.set(categoriesResponse[i].fullPath, url);
    }
    setCategoriesUrl(categoriesUrl);
  };

  const handleLookAheadSearch = (event: ChangeEvent, type: string) => {
    event.persist();

    const element = event.currentTarget as HTMLInputElement;
    if (element.value !== "") {
      sessionStorage.removeItem("selectedPage");
      sessionStorage.removeItem("selectedPageOffSet");
    }
    setInput(element.value);
    retrieveSuggestions(element.value);
  };

  const retrieveSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID;
        const catalogId = mySite.catalogID;

        const parameters: any = {
          responseFormat: "application/json",
          storeId: storeID,
          term: searchTerm,
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          suggestType: ["Keyword", "Product", "Category"],
          ...xPayloadBase,
        };

        siteContentService.findSuggestionsUsingGET(parameters).then((res) => {
          if (res.status === OK) {
            const keywordSuggestions = res?.data?.suggestionView?.find(
              (i) => i.identifier === "Keyword"
            )?.entry;
            const productResponse = res?.data?.suggestionView?.find(
              (i) => i.identifier === "Product"
            )?.entry;
            if (keywordSuggestions) {
              let list: string[] = [];
              generateSuggestionList(keywordSuggestions, searchTerm, list);
              generateCatgoriesAndBrandsSuggestions(searchTerm, list);
              generateProductSuggestion(productResponse, list);
              setNameList(list);
            }
          }
        });
      }, 300);
    }
    clearKeywords();
    clearSuggestions();
  };

  const generateCatgoriesAndBrandsSuggestions = (
    userInput: string,
    listTemp: string[]
  ) => {
    const regex = new RegExp(userInput, "ig");
    const matchedCategories = categories?.filter((e) => e.match(regex));
    let lists: object[] = [];
    if (matchedCategories) {
      for (let suggestion of matchedCategories) {
        if (lists.length === 4) {
          break;
        }
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        /*if (suggestion.includes(">")) {
          let name = suggestion.split(">");
          suggestionSkeleton.name = name[1];
          if(name[2] && name[2] !== undefined) {
            suggestionSkeleton.name = name[1] + ">"+ name[2];
          }
        } else {
          suggestionSkeleton.name = suggestion;
        }*/
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = categoriesUrl.get(suggestion);
        nameListIndex++;
        lists.push(suggestionSkeleton);
        listTemp.push(suggestion);
      }
    }
    setCategorySuggestions(lists);
    setShowCategories(true);
    const matchedBrands = brands?.filter((e) => e.match(regex));
    let lists2: object[] = [];
    if (matchedBrands) {
      for (let suggestion of matchedBrands) {
        if (lists2.length === 4) {
          break;
        }
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion;
        nameListIndex++;
        lists2.push(suggestionSkeleton);
        listTemp.push(suggestion);
      }
    }
    setBrandSuggestions(lists2);
    setShowBrands(true);
  };

  const generateProductSuggestion = (
    productsResponse: any[],
    listTemp: string[]
  ) => {
    let lists2: object[] = [];
    if (productsResponse && productsResponse.length > 0) {
      for (let suggestion of productsResponse) {
        if (lists2.length === 4) {
          break;
        }
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion.name;
        suggestionSkeleton.thumbnail = suggestion.thumbnail;
        suggestionSkeleton.partNumber = suggestion.partNumber;
        suggestionSkeleton.url = suggestion.seo.href;
        nameListIndex++;
        lists2.push(suggestionSkeleton);
        listTemp.push(suggestion.name);
      }
    }
    setProductSuggestions(lists2);
    setShowProducts(true);
  };

  const generateSuggestionList = (
    keywordSuggestions: any[],
    userInput: string,
    listTemp: string[]
  ) => {
    const lists: object[] = [];

    listTemp.push(userInput);
    const listTemp2: string[] = [];

    for (let suggestion of keywordSuggestions) {
      if (keywordSuggestions) {
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion.term;
        suggestionSkeleton.url =
          SEARCH + "?" + SEARCHTERM + "=" + suggestion.term;
        listTemp.push(suggestion.term);
        lists.push(suggestionSkeleton);
        listTemp2.push(suggestion.term);
        nameListIndex++;
      }
    }
    setKeywordSuggestions(lists);
    setKeywordsToLocalStorage(listTemp2);
    setShowKeywords(true);
  };

  const callRegex = (str: string) => {
    const regex2 = new RegExp(">", "ig");
    let arr: string[];
    if (str.match(regex2)) {
      arr = str.split(">");
      str = arr[arr.length - 1].trim();
    }
    return str;
  };
  const onKeyDown = (e) => {
    let len = nameList ? nameList.length : 0;
    let str = "";
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault();

      if (index === 0) {
        return;
      }
      setIndex(index - 1);
      if (nameList) {
        str = callRegex(nameList[index - 1]);
        setInput(str);
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault();

      if (index === len - 1) {
        setIndex(0);
        if (nameList) {
          str = callRegex(nameList[0]);
          setInput(str);
        }
        return;
      }
      setIndex(index + 1);
      if (nameList) {
        str = callRegex(nameList[index + 1]);
        setInput(str);
      }
    }
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    clearSuggestions();

    if (input && input.trim() !== "") {
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();
      const parameters: any = {
        storeId: storeID,
        searchTerm: searchTerm,
        ...payloadBase,
      };
      setTimeout(() => {
        (async () => {
          let res = await AdobeService.track({
            pageName: "home",
            onClickLinkName: "search catalog:<nameplate>",
            onClick: `search catalog:${searchTerm}`,
            variantName: "search-catalog",
            siteSection: "home",
            hierarchy: "home",
            eventType: "searchCatalog",
          });
          AdobeService.callSatellite(res);
        })();
      }, 2000);
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            url = res?.data.redirecturl;
            if (url === undefined) {
              url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
            }
            redirectTo(url);
          }
        })
        .catch((e) => {
          url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
          redirectTo(url);
        });
    }
  };

  const redirectTo = (url: string) => {
    clearSuggestions();
    setShowSearchBar(false);
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  const clickAway = (prev) => {
    setShowSearchBar(!prev);
  };

  const setShowSearchBar = (boolean) => {
    if (boolean) {
      openSearchBar();
    } else {
      closeSearchBar();
    }
  };

  return (
    <ClickAwayListener onClickAway={clickAway}>
      <StyledSearchBar className="searchbar-global quick-search">
        <form
          className="search quick-search__form container--sm m-b-s"
          onSubmit={submitSearch}
          noValidate>
          <div className="quick-search__inner flex f-vertical-center">
            <StyledTextField
              id="global-search"
              margin="normal"
              size="small"
              title="search-quick"
              autoFocus
              autoComplete="off"
              type="text"
              disabled={inputDisabled}
              placeholder={searchField}
              value={input}
              name="searchTerm"
              onChange={(e) => handleLookAheadSearch(e, "searchTerm")}
              onKeyDown={onKeyDown}
              className="quick-search__input"
              InputProps={{
                endAdornment: (
                  <>
                    {showKeywords ||
                    showCategories ||
                    showBrands ||
                    showProducts ? (
                      <InputAdornment position="end" title="quick-search">
                        <StyledIconButton
                          onClick={clearSuggestionsAndInputField}>
                          <CloseIcon titleAccess={t("SearchBar.Clear")} />
                        </StyledIconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment
                        title="quick-search"
                        position="start"
                        className="quick-search__button">
                        <span onClick={() => setShowSearchBar(!showSearchBar)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18">
                            <g>
                              <g>
                                <path
                                  fill="#324047"
                                  d="M7.528 14.315c-1.852 0-3.434-.656-4.746-1.969C1.469 11.033.813 9.451.813 7.599c0-1.875.656-3.47 1.969-4.782C4.094 1.504 5.676.847 7.528.847c1.875 0 3.475.657 4.799 1.97 1.324 1.313 1.986 2.907 1.986 4.782 0 1.852-.668 3.434-2.004 4.747-1.336 1.313-2.93 1.97-4.781 1.97zM17.02 17.9c.094 0 .188-.047.281-.14.188-.165.188-.34 0-.528l-4.448-4.447.172-.183c1.232-1.349 1.882-2.932 1.95-4.75l.006-.323c0-2.04-.732-3.785-2.197-5.238C11.319.837 9.567.11 7.528.11c-2.04 0-3.785.732-5.238 2.197C.837 3.772.11 5.536.11 7.598c0 2.063.72 3.815 2.162 5.256 1.442 1.442 3.193 2.162 5.256 2.162 1.737 0 3.258-.515 4.562-1.545l.217-.178 4.467 4.536c.035.035.09.057.165.066z"></path>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />
          </div>
        </form>
        {(showKeywords || showCategories || showBrands || showProducts) && (
          <ClickAwayListener
            onClickAway={() => {
              clearSuggestionsAndInputField();
            }}>
            <div
              className="klevu-search results-display"
              data-klevu-search="klevu">
              <div className="klevu-search__inner">
                <div className="klevu-search__sidebar">
                  <div className="klevu-search__auto-suggestions">
                    <h3 className="klevu-search__title">{keywordTitle}</h3>
                    {showKeywords && keywordSuggestions.length > 0 ? (
                      <ul className="klevu-search__suggestions-list">
                        {keywordSuggestions?.map((e: any, i: number) => (
                          <StyledMenuItem className="klevu-search__list-item">
                            <Link
                              className="klevu-search__suggestions-link"
                              key={`brand-${i}`}
                              to={e.url}
                              onClick={() =>
                                clearSuggestionsAndUpdateInputField(e.name)
                              }>
                              <StyledMenuTypography
                                variant="body1"
                                className={e.arrIndex === index ? "active" : ""}
                                key={e.arrIndex}
                                id={`megamenu_department_${e.id}`}
                                title={e.name}>
                                {e.name}
                              </StyledMenuTypography>
                            </Link>
                          </StyledMenuItem>
                        ))}
                      </ul>
                    ) : (
                      <div className="klevu-search__no-results">
                        No suggestions found for your query
                      </div>
                    )}
                  </div>
                  <div className="klevu-search__category-suggestions">
                    <h3 className="klevu-search__title klevu-search__title--categories">
                      {categoryTitle}
                    </h3>
                    {showCategories && categorySuggestions.length > 0 ? (
                      <ul className="klevu-search__category-list">
                        {categorySuggestions?.map((e: any, i: number) => (
                          <StyledMenuItem className="klevu-search__list-item">
                            <Link
                              className="klevu-search__category"
                              key={`category-${i}`}
                              to={e.url}
                              onClick={(evt) =>
                                clearSuggestionsAndUpdateInputField(e.name)
                              }>
                              <StyledMenuTypography
                                variant="body1"
                                className={
                                  e.arrIndex === index
                                    ? "klevu-search__category-name active"
                                    : "klevu-search__category-name"
                                }
                                key={e.arrIndex}
                                id={`megamenu_department_${e.id}`}
                                title={e.name}>
                                {e.name}
                              </StyledMenuTypography>
                            </Link>
                          </StyledMenuItem>
                        ))}
                      </ul>
                    ) : (
                      <div className="klevu-search__no-results">
                        No categories matched your query
                      </div>
                    )}
                  </div>

                  <div className="klevu-search__product-suggestions">
                    <h3 className="klevu-search__title">{productTitle}</h3>
                    {showProducts && productSuggestions.length > 0 ? (
                      <ul className="klevu-search__product-list">
                        {productSuggestions?.map((e: any, i: number) => (
                          <StyledMenuItem>
                            <Link
                              key={`product-${i}`}
                              className="klevu-search__product-link"
                              to={e.url}
                              onClick={(evt) =>
                                clearSuggestionsAndUpdateInputField(e.name)
                              }>
                              <div className="klevu-search__product-image">
                                <img
                                  src={e.thumbnail}
                                  className="thumbnail-product"
                                />
                              </div>

                              <StyledMenuTypography
                                variant="body1"
                                className={e.arrIndex === index ? "active" : ""}
                                key={e.arrIndex}
                                id={`megamenu_department_${e.id}`}
                                title={e.name}>
                                <span className="klevu-search__product-name klevu-name">
                                  {e.name} <br /> {e.partNumber}
                                </span>
                              </StyledMenuTypography>
                            </Link>
                          </StyledMenuItem>
                        ))}
                      </ul>
                    ) : (
                      <div
                        className="klevu-search__no-results"
                        style={{ paddingBottom: "20px" }}>
                        No products matched your query
                      </div>
                    )}
                  </div>
                  {/*showBrands && (
                                <>
                                  <StyledMenuTypography
                                    variant="body2"
                                    className="searchBar-resultsCategory">
                                    {brandTitle}
                                  </StyledMenuTypography>
                                  {brandSuggestions?.map((e: any, i: number) => (
                                    <Link
                                      key={`brand-${i}`}
                                      to={e.url}
                                      onClick={(evt) =>
                                        clearSuggestionsAndUpdateInputField(e.name)
                                      }>
                                      <StyledMenuItem>
                                        <StyledMenuTypography
                                          variant="body1"
                                          className={e.arrIndex === index ? "active" : ""}
                                          key={e.arrIndex}
                                          id={`megamenu_department_${e.id}`}
                                          title={e.name}>
                                          {e.name}
                                        </StyledMenuTypography>
                                      </StyledMenuItem>
                                    </Link>
                                  ))}
                                </>
                              ) */}
                </div>
              </div>
            </div>
          </ClickAwayListener>
        )}
      </StyledSearchBar>
    </ClickAwayListener>
  );
};

interface SearchBarProps {
  showSearchBar: boolean;
  openSearchBar: any;
  closeSearchBar: any;
}

export { SearchBar };
