/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//Custom libraries
import { OrderItemTable } from "../../widgets/order-item-table";
import FormattedPriceDisplay from "../../widgets/formatted-price-display";
//UI
import { Divider } from "@material-ui/core";
import {
  StyledBox,
  StyledButton,
  StyledGrid,
  StyledIconButton,
  StyledPaper,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { numItemsSelector } from "../../../redux/selectors/order";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MiniCartOrderList } from "./order-list";
import * as orderActions from "../../../redux/actions/order";
import EmptyCart from "../../common/EmptyCart";
import FPRPoints from "../../common/FPRPoints";

interface MiniCartContentProps {
  title: string;
  orderType: string;
  orderItems: any[];
  subtotalLabel: string;
  subtotal: number | null;
  subtotalCurrency: string;
  emptyCartMsg: string;
  cartLinkLabel: string;
  checkoutLinkLabel: string;
  handleCartOnClick: Function;
  handleCheckoutOnClick: Function;
  handleClose: Function;
  isOrderOwner: boolean;
  isB2B: boolean;
}

const StyledMiniCartContent = React.forwardRef<any, MiniCartContentProps>(
  (props: any, ref: any) => {
    const dispatch = useDispatch();
    const {
      title,
      orderType,
      orderItems,
      subtotalLabel,
      subtotal,
      subtotalCurrency,
      emptyCartMsg,
      cartLinkLabel,
      checkoutLinkLabel,
      handleCartOnClick,
      handleCheckoutOnClick,
      handleClose,
      isOrderOwner,
      isB2B,
    } = props;
    const numItems = useSelector(numItemsSelector);
    const cleartCart = () => {
      dispatch(orderActions.RESET_CART_ACTION());
      dispatch(orderActions.CLEAR_CART_ACTION());
    };
    return (
      <StyledPaper
        ref={ref}
        className="mini-cart-container"
        data-testid="mini-cart-popper">
        {/* {!isB2B ? (
          <StyledTypography
            variant="h6"
            className="horizontal-padding-2 top-padding-2">
            {title}
          </StyledTypography>
        ) : (
          <>
            <StyledTypography
              variant="h6"
              style={{ overflowWrap: "break-word" }}
              className="horizontal-padding-2 top-padding-2">
              {title}
            </StyledTypography>
            <StyledTypography
              variant="body1"
              className="horizontal-padding-2 bottom-padding-2">
              {orderType}
            </StyledTypography>
          </>
        )} */}
        <div className="side-cart-header">
          <h3 className="side-cart-header__heading text-align-center">
            Shopping Cart
          </h3>{" "}
          <span className="side-cart-header__item-count">
            {numItems} {numItems === 1 || numItems === 0 ? "item" : "items"}
          </span>
          <div
            onClick={(e) => {
              handleClose(e);
            }}
            className="side-cart-header__close close-cross"
          />
        </div>

        <Divider className="heading" />
        {orderItems.length > 0 ? (
          <>
            <StyledBox>
              <MiniCartOrderList
                data={orderItems}
                miniCartView={true}
                handleMiniCartClose={handleClose}
              />
            </StyledBox>
            <Divider />
            <StyledGrid
              container
              className="horizontal-padding-2 vertical-padding-2">
              <StyledGrid item xs={7}>
                <StyledTypography variant="body1">SUBTOTAL</StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={5}>
                <StyledTypography variant="body1" align="right">
                  <FormattedPriceDisplay
                    min={subtotal}
                    currency={subtotalCurrency}
                  />
                </StyledTypography>
              </StyledGrid>
              <StyledGrid item sm={12} xs={12}>
                <StyledTypography
                  className="fpr-minicart"
                  variant="body1"
                  align="right">
                  <FPRPoints amount={subtotal} />
                </StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={7}>
                <StyledTypography variant="body1">SHIPPING</StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={5}>
                <div className="side-cart-overview__data side-cart-overview__data--light">
                  Calculated at checkout
                </div>
              </StyledGrid>
            </StyledGrid>
          </>
        ) : (
          <EmptyCart closeMinicart={handleClose} />
        )}

        <Divider />
        {/* <StyledBox
          className="horizontal-padding-2 vertical-padding-1"
          align="center">
          {!isB2B ? (
            <StyledButton
              color="secondary"
              className="right-margin-1"
              onClick={handleCartOnClick}>
              {cartLinkLabel}
            </StyledButton>
          ) : isOrderOwner ? (
            <>
              <StyledButton
                color="secondary"
                className="right-margin-1"
                onClick={handleCartOnClick}>
                {cartLinkLabel}
              </StyledButton>
              <StyledButton
                color="primary"
                disabled={!orderItems || orderItems.length <= 0}
                onClick={handleCheckoutOnClick}>
                {checkoutLinkLabel}
              </StyledButton>
            </>
          ) : (
            <StyledButton color="secondary" onClick={handleCartOnClick}>
              {cartLinkLabel}
            </StyledButton>
          )}

        </StyledBox> */}
        <a
          onClick={handleCartOnClick}
          className="side-cart-overview__cart-cta btn btn--border">
          {cartLinkLabel}
        </a>
        <StyledIconButton
          color="primary"
          className="side-cart-overview__continue-shopping"
          style={{ border: 0, padding: 0, fontSize: 14 }}
          onClick={handleClose}>
          Continue Shopping
        </StyledIconButton>
      </StyledPaper>
    );
  }
);

export default StyledMiniCartContent;
