export const COMPONENT_CONFIGS = {
  SEARCH_NOW: {
    MODEL: {
      visible: true,
    },
    VIN: {
      visible: false,
    },
  },
};
