/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import wishListService from "../../../_foundation/apis/transaction/wishList.service";
//Redux
import * as ACTIONS from "../../action-types/wishList";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";

/**
 * Saga worker to invoke get wish list details
 */
export function* fetchWishListDetails(action: any) {
  try {
    const payload = action.payload;
    let currentUser: any = localStorageUtil.get('currentUser');
    let response = {
      data: []
    };
    if (currentUser && currentUser?.userLoggedIn) {
      response = yield call(wishListService.findWishlist, payload);
    }
    const WishListData = response.data;

    yield put({
      type: ACTIONS.WISHLIST_GET_SUCCESS,
      response: WishListData,
    });
  } catch (error) {
    yield put({ type: ACTIONS.WISHLIST_GET_ERROR, error });
  }
}
