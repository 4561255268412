/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { debounce } from "redux-saga/effects";
//Redux
import * as ACTIONS from "../../action-types/search-now";
import * as WORKERS from "../workers/search-now";

export function* watchSaga() {
  yield debounce(50, ACTIONS.FETCH_MODEL_YEAR_REQUESTED, WORKERS.fetchModels);
  yield debounce(50, ACTIONS.FETCH_VEHICLES_REQUESTED, WORKERS.fetchVehicles);
}
