/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory, withRouter, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
import Script from "react-load-script";
//UI
import {
  StyledGrid,
  StyledContainer,
  StyledTypography,
  StyledFooter,
  StyledBox,
} from "@hcl-commerce-store-sdk/react-component";
import { OLD_HOME } from "../../constants/routes";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./FooterStyle.scss";
import FooterSm from "./FooterSm";
import { useTheme } from "@material-ui/core/styles";
import { useSite } from "../../_foundation/hooks/useSite";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../redux/selectors/user";
import SessionTimeOutPopup from "../pages/sign-in/SessionTimeOutPopup";
import AdobeService from "../../_foundation/adobeService/adobeService";
import {
  CATEGORYLIST,
  SUBCATEGORYLIST,
} from "../../_foundation/constants/common";

function Footer(props: any) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down(600));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up(600));
  const { mySite } = useSite();
  const loginStat = useSelector(loginStatusSelector);
  const location = useLocation();
  const [displayHeader, setDisplayHeader] = React.useState<any>(true);
  const year = new Date().getFullYear();
  React.useEffect(() => {
    let value;
    value =
      location.pathname.includes("checkout") ||
      location.pathname.includes("order-confirmation")
        ? false
        : true;
    setDisplayHeader(value);
  }, [location]);

  const Accessories = () => {
    const Accessories = [
      {
        linkText: t("Footer.Accessories.Interior"),
        linkURL: "/lincoln-interior",
      },
      {
        linkDescription: t("Footer.Accessories.Exterior"),
        linkText: t("Footer.Accessories.Exterior"),
        linkURL: "/lincoln-exterior",
      },
      {
        linkText: t("Footer.Accessories.Electronics"),
        linkURL: "/lincoln-electronics",
      },
      {
        linkText: t("Footer.Accessories.Wheels"),
        linkURL: "/lincoln-wheels",
      },
      {
        linkText: t("Footer.Accessories.FordAccessories"),
        linkURL: "https://accessories.ford.com/",
      },
    ];
    return (
      <div className="accessories footer-nav__column__link-ul">
        <StyledTypography
          variant="overline"
          className="footer-nav__column-title title--font uc">
          {t("Footer.Accessories.Label")}
        </StyledTypography>
        <StyledTypography variant="caption">
          {Accessories.map((v: any) => (
            <div key={v.linkURL} className="footer-nav__list-item">
              {v.linkText === "Ford Accessories" ? (
                <a
                  className="footer-nav__link  uc title--xxs"
                  href={v.linkURL}
                  target="_blank">
                  {v.linkText}
                </a>
              ) : (
                <Link
                  className="footer-nav__link  uc title--xxs"
                  key={v.linkURL + "/"}
                  to={v.linkURL}>
                  {v.linkText}
                </Link>
              )}
            </div>
          ))}
        </StyledTypography>
      </div>
    );
  };

  const history = useHistory();
  useEffect(() => {
    if (window.location.href.includes("LogoutRedirect")) {
      handleLogout();
      history.push("/");
    }
  });
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    //console.log("storage cleared");
  };

  const MyProfile = () => {
    const isLoggedIn = loginStat;
    var protocol = window.location.protocol;
    var port = !window.location.port.trim() ? "" : ":" + window.location.port;
    var url = window.location.href;
    var currentURL =
      url.indexOf("&krypto") > 0
        ? url.substring(0, url.indexOf("&krypto"))
        : url;
    const FMA_LOGIN_LINK = `${
      mySite.storeCfg.userData["FMA_SIGN_IN"]
    }?redirectURL=${protocol}//${
      window.location.hostname
    }${port}/LoginRedirect?storeId=${mySite.storeID}&catalogId=${
      mySite.catalogID
    }&langId=-1&currentURL=${encodeURIComponent(currentURL)}`;
    const FMA_LOGOUT_LINK = `${
      mySite.storeCfg.userData["FMA_SIGN_OUT"]
    }?redirectURL=${encodeURIComponent(
      `${protocol}//${window.location.hostname}${port}/LogoutRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&lang=en_us`
    )}&currentURL=${encodeURIComponent(
      window.location.origin
    )}&pid=${localStorage.getItem("pid")}&sessionId=${localStorage.getItem(
      "sessionId"
    )}`;
    const MyProfile = [
      loginStat
        ? {
            linkText: t("LOGOUT"),
            linkURL: FMA_LOGOUT_LINK,
          }
        : {
            linkText: t("LOGIN"),
            linkURL: FMA_LOGIN_LINK,
          },
      !loginStat
        ? {
            linkText: t("Create An Account"),
            linkURL:FMA_LOGIN_LINK,
          }
        : {},
      {
        linkText: t("Footer.MyProfile.AccountDetails"),
        linkURL: isLoggedIn ? "/account/addresses" : FMA_LOGIN_LINK,
      },
      {
        linkText: t("Footer.MyProfile.OrdersAndReturns"),
        linkURL: isLoggedIn ? "/account/order-history" : FMA_LOGIN_LINK,
      },
      {
        linkText: t("Footer.MyProfile.MyGarage"),
        linkURL: isLoggedIn ? "/account/my-garage" : FMA_LOGIN_LINK,
      },
    ];

    return (
      <div className="contact-us footer-nav__column__link-ul">
        <StyledTypography
          variant="overline"
          className="footer-nav__column-title title--font uc">
          {t("Footer.MyProfile.Label")}
        </StyledTypography>
        <StyledTypography variant="caption">
          {MyProfile.map(
            (v: any) =>
              v.linkURL && (
                <div key={v.linkURL} className="footer-nav__list-item">
                  {
                    <a
                      className="footer-nav__link  uc title--xxs"
                      href={v.linkURL}>
                      {v.linkText}
                    </a>
                  }
                </div>
              )
          )}
        </StyledTypography>
      </div>
    );
  };

  const Resources = () => {
    const { t } = useTranslation();
    const resources = [
      {
        linkText: t("Footer.Resources.FAQ"),
        linkURL: "/Faq",
      },
      {
        linkText: t("Footer.Resources.ContactUs"),
        linkURL: "/contact-us",
      },
      {
        linkText: t("Footer.Resources.ShippingAndDelivery"),
        linkURL: "/shipping-delivery",
      },
      {
        linkText: t("Footer.Resources.Returns"),
        linkURL: "/return",
      },
      {
        linkText: t("Footer.Resources.MakeReturn"),
        linkURL: "/MakeReturn",
      },
      {
        linkText: t("Footer.Resources.TermsAndConditions"),
        linkURL: "/terms-conditions",
      },
      {
        linkText: t("Footer.Resources.WarrantyInfo"),
        linkURL: "/warranty-information",
      },
    ];

    const [onClickEventAdd, setOnClickEventAdd] = useState(false);

    function adobeTagImpl(objData) {
      (async () => {
        let res = await AdobeService.track(objData);
        AdobeService.callSatellite(res);
      })();
    }

    const addOnClickEvent = () => {
      if (!onClickEventAdd) {
        let links = document.getElementsByTagName("a");
        for (let index = 0; index < links.length; index++) {
          let title = links[index].title;
          // console.log("title===>",title)
          let href = links[index].href;
          // console.log("href====>",href)
          if (href === "https://www.ford.com/") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:ford.com",
                onClickLinkName: "referral:exit",
                referredTo: "https://www.ford.com/",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          } else if (href === "https://www.lincoln.com/") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:lincoln.com",
                onClickLinkName: "referral:exit",
                referredTo: "https://www.lincoln.com/",
                variantName: "referral-exit",
                eventTypr: "product details:add to cart",
              });
            });
          } else if (href === "https://corporate.ford.com/contact.html") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:ford:corporate:home",
                onClickLinkName: "referral:exit",
                referredTo: "https://corporate.ford.com/contact.html",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          } else if (href === "https://parts.ford.com/en.html") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:fpol:home",
                onClickLinkName: "referral:exit",
                referredTo: "https://parts.ford.com/shop/en/us",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          } else if (href === "https://collision.ford.com/") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:collision:home",
                onClickLinkName: "referral:exit",
                referredTo: "https://collision.ford.com/",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          } else if (href === "https://powerstrokediesel.com/") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:powerstrokediesel.com",
                onClickLinkName: "referral:exit",
                referredTo: "https://powerstrokediesel.com/",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          } else if (href === "https://performance.ford.com/home.html") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:fr:home",
                onClickLinkName: "referral:exit",
                referredTo: "https://performance.ford.com/home.html",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          } else if (
            href ===
            "https://www.ford.com/support/service-maintenance/tire-finder/"
          ) {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:flmo:service:tire finder",
                onClickLinkName: "referral:exit",
                referredTo:
                  "https://www.ford.com/support/service-maintenance/tire-finder/",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          } else if (href === "https://www.vpartsinc.com/") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:vpartsinc.com",
                onClickLinkName: "referral:exit",
                referredTo: "https://www.vpartsinc.com/",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          } else if (href === "https://chargers.ford.com/") {
            links[index].addEventListener("click", () => {
              adobeTagImpl({
                pageName: "home",
                siteSection: "home",
                hierarchy: "home",
                onClickName: "referral:fcg:home",
                onClickLinkName: "referral:exit",
                referredTo: "https://chargers.ford.com/",
                variantName: "referral-exit",
                eventTypr: "referralExit",
              });
            });
          }
        }
        setOnClickEventAdd(true);
      }
    };
    useEffect(() => {
      addOnClickEvent();
    });
    return (
      <>
        <div className="contact-us footer-nav__column__link-ul">
          <StyledTypography
            variant="overline"
            className="footer-nav__column-title title--font uc">
            {t("Footer.Resources.Label")}
          </StyledTypography>
          <StyledTypography variant="caption">
            {resources.map((v) => (
              <div key={v.linkURL} className="footer-nav__list-item">
                <Link
                  className="footer-nav__link  uc title--xxs"
                  key={v.linkURL + "/"}
                  to={v.linkURL}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}>
                  {v.linkText}
                </Link>
              </div>
            ))}
          </StyledTypography>
        </div>
      </>
    );
  };

  const RelatedLinks = () => {
    const RelatedLinks = [
      {
        linkText: t("Footer.RelatedLinks.Ford"),
        linkURL: "https://www.ford.com/",
      },
      {
        linkText: t("Footer.RelatedLinks.Lincoln"),
        linkURL: "https://www.lincoln.com/",
      },
      {
        linkText: t("Footer.RelatedLinks.ContactFord"),
        linkURL: "https://corporate.ford.com/contact.html",
      },
      {
        linkText: t("Footer.RelatedLinks.Racing"),
        linkURL: "https://performance.ford.com/home.html",
      },
      {
        linkText: t("Footer.RelatedLinks.FordParts"),
        linkURL: "https://parts.ford.com/en.html",
      },
      {
        linkText: t("Footer.RelatedLinks.FordCollision"),
        linkURL: "https://collision.ford.com/",
      },
      {
        linkText: t("Footer.RelatedLinks.PowerStrokeDiesel"),
        linkURL: "https://powerstrokediesel.com/",
      },
      {
        linkText: t("Footer.RelatedLinks.TireFinder"),
        linkURL:
          "https://www.ford.com/support/service-maintenance/tire-finder/",
      },
      {
        linkText: t("Footer.RelatedLinks.VintageParts"),
        linkURL: "https://www.vpartsinc.com/",
      },
    ];
    return (
      <>
        <div className="contact-us footer-nav__column__link-ul">
          <StyledTypography
            variant="overline"
            className="footer-nav__column-title title--font uc">
            {t("Footer.RelatedLinks.Label")}
          </StyledTypography>
          <StyledTypography variant="caption">
            {RelatedLinks.map((v: any) => (
              <div key={v.linkURL} className="footer-nav__list-item">
                <a
                  className="footer-nav__link  uc title--xxs"
                  href={v.linkURL}
                  target="_blank"
                  rel="noopener noreferrer">
                  {v.linkText}
                </a>
              </div>
            ))}
          </StyledTypography>
        </div>
      </>
    );
  };
  const [adobeTagCall, setAdobeTagCall] = useState(0);

  React.useEffect(() => {
    if (mySite && adobeTagCall < 1) {
      if (
        (location.pathname === "/" || location.pathname === OLD_HOME + "/") &&
        adobeTagCall < 1
      ) {
        (async () => {
          let res = await AdobeService.track({
            pageName: "home",
            variantName: "global-load",
            siteSection: "home",
            hierarchy: "home",
            eventType: "homeload",
          });
          AdobeService.callSatellite(res);
        })();
      }
      setAdobeTagCall(1);
    }
    if (
      (location.pathname === "/LearnMoreRewards" ||
        location.pathname === OLD_HOME + "/LearnMoreRewards") &&
      adobeTagCall < 1
    ) {
      (async () => {
        let res = await AdobeService.track({
          pageName: "Lincoln Access rewards",
          variantName: "global-nameplate-load",
          siteSection: "Lincoln Access rewards",
          hierarchy: "Lincoln Access rewards",
          eventType: "Lincoln Access rewards",
        });
        AdobeService.callSatellite(res);
        console.log("11111 reward location.pathname", location.pathname);
      })();
    }
    // console.log("11111 home");
  }, [location.pathname]);

  // Adobe Tag Manager

  useEffect(() => {
    CATEGORYLIST.map((d) => {
      if (location.pathname == d.url || location.pathname == OLD_HOME + d.url) {
        (async () => {
          let res = await AdobeService.track({
            pageName: `accessories:${d.category}`,
            noProduct: `accessories:${d.category}`,
            variantName: "global-nameplate-load",
            siteSection: "accessories",
            hierarchy: "accessories",
            eventType: "accessories load",
          });
          AdobeService.callSatellite(res);
        })();
      }
    });
  }, [location.pathname]);

  React.useEffect(() => {
    SUBCATEGORYLIST.map((d) => {
      if (location.pathname == d.url || location.pathname == OLD_HOME + d.url) {
        (async () => {
          let res = await AdobeService.track({
            pageName: `accessories:${d.category}:${d.subCategory}`,
            noProduct: `accessories:${d.category}:${d.subCategory}`,
            variantName: "global-nameplate-load",
            siteSection: "accessories",
            hierarchy: "accessories",
            eventType: "accessories load",
          });
          AdobeService.callSatellite(res);
        })();
      }
    });
  }, [location.pathname]);

  // Adobe Tag Manager
  useEffect(() => {
    if (
      location.pathname == "/account/wish-list" ||
      location.pathname == OLD_HOME + "/account/wish-list"
    ) {
      (async () => {
        let res = await AdobeService.track({
          pageName: "account:wishlist",
          variantName: "account-details",
          siteSection: "account",
          hierarchy: "account:wishlist",
          eventType: "account wishlist",
        });
        AdobeService.callSatellite(res);
      })();
    }
    // console.log('11111 wish',location.pathname)
  }, [location.pathname]);
  //  const collectionlist=[
  //   {collections:"Wheels",url:"/lincoln-wheels"},
  //   {collections:"Cargo",url:"/bed-cargo-area-products"},
  //   {collections:"Electronics",url:"/lincoln-electronics"},
  //   {collections:"Protections",url:"/lincoln-exterior"},

  //   {collections:"Security",url:"/lincoln-vehicle-security/"},
  //   {collections:"Sunshade",url:"/lincoln-covers-and-protectors/"},
  //   {collections:"Liner",url:"/lincoln-liners-and-mats/"},
  //   {collections:"Luggage",url:"/lincoln-racks-and-carriers/"},
  //  ]
  //  React.useEffect(()=>{
  //   collectionlist.map((d)=>{
  //     if(location.pathname == d.url){
  //       (async () => {
  //         let res = await AdobeService.track({
  //           pageName: `accessories:collection:${d.collections}`,
  //           variantName: "global-nameplate-load",
  //           siteSection: "accessories",
  //           hierarchy: "accessories",
  //           eventType: "accessories collections",
  //         });
  //         AdobeService.callSatellite(res);
  //       })();
  //     }
  //   })
  //  },[location.pathname])
  return (
    <>
      {displayHeader && (
        <StyledFooter style={{ marginTop: "0px" }}>
          {isLargeDesktop && (
            <StyledBox sx={{ background: "#231f20", p: 2, color: "white" }}>
              <StyledContainer>
                <StyledGrid
                  container
                  spacing={2}
                  className="footer-top footer-nav__list">
                  <StyledGrid item className="footer-nav__column">
                    <Accessories />
                  </StyledGrid>
                  <StyledGrid item className="footer-nav__column">
                    <MyProfile />
                  </StyledGrid>
                  <StyledGrid item className="footer-nav__column">
                    <Resources />
                  </StyledGrid>
                  <StyledGrid item className="footer-nav__column">
                    <RelatedLinks />
                  </StyledGrid>
                </StyledGrid>
              </StyledContainer>
            </StyledBox>
          )}
          {isSmallDesktop && (
            <StyledBox sx={{ background: "#231f20", color: "white" }}>
              <FooterSm />
            </StyledBox>
          )}

          <StyledBox sx={{ background: "white", color: "black" }}>
            <StyledContainer className="footer-legal">
              <StyledGrid
                container
                className="footer-legal__nav footer-bottom"
                alignItems="center">
                <StyledTypography className="footer-legal__nav-list-item footer-legal__copy">
                  © {year} Ford Motor Company
                </StyledTypography>

                <StyledGrid className="footer-legal__nav-list-item" item>
                  <Link to="/sitemap">{t("Site map")}</Link>
                </StyledGrid>

                <StyledGrid className="footer-legal__nav-list-item" item>
                  <Link to="/contact-us">{t("Contact Us")}</Link>
                </StyledGrid>

                <StyledGrid className="footer-legal__nav-list-item" item>
                  <Link
                    to="/terms-conditions"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}>
                    {t("Terms & Conditions")}
                  </Link>
                </StyledGrid>

                <StyledGrid className="footer-legal__nav-list-item" item>
                  <a href="https://www.ford.com/help/privacy/">
                    {t("Privacy Notice")}
                  </a>
                </StyledGrid>

                <StyledGrid className="footer-legal__nav-list-item" item>
                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                    Cookie Settings
                  </button>
                </StyledGrid>

                <StyledGrid className="footer-legal__nav-list-item" item>
                  <a href="https://www.ford.com/help/privacy/ccpa/">
                    {t("Your Privacy Choices")}
                  </a>
                </StyledGrid>

                <StyledGrid className="footer-legal__nav-list-item" item>
                  <a href="https://www.ford.com/help/contact/#accessibility">
                    {t("Accessibility")}
                  </a>
                </StyledGrid>
                <div className="opt-out_container">
                  <img
                    src="/FordUSLincoln/EmeraldSAS/images/type-icons/opt-out_small.png"
                    alt="opt out icon"
                  />
                </div>
                <span className="footer-legal__nav__logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="88"
                    height="65"
                    viewBox="0 0 88 65">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#231f20"
                            d="M28.51 57.275l-.002 4.567-.138-.163a2707.687 2707.687 0 0 0-3.4-4.006l-.036-.041c-.133-.152-.316-.36-.636-.361l-1.086-.001-.002 7.495h1.678l.001-4.694 3.657 4.303c.15.165.39.386.749.393h.891l.002-7.492z"></path>
                        </g>
                        <g>
                          <path
                            fill="#231f20"
                            d="M86.218 57.285v4.568l-.14-.164c-1.66-1.957-3.361-3.963-3.4-4.007l-.038-.042c-.132-.152-.314-.359-.634-.36h-1.085l-.001 7.494 1.676.001.001-4.694 3.658 4.303c.149.165.387.386.747.392h.891l.002-7.491z"></path>
                        </g>
                        <g>
                          <path
                            fill="#231f20"
                            d="M13.372 57.27l-.002 7.492h1.696l.002-7.491z"></path>
                        </g>
                        <g>
                          <path
                            fill="#231f20"
                            d="M60.11 61.105c-.036 2.12-1.849 3.785-4.126 3.783-2.34.001-4.105-1.65-4.104-3.84 0-2.175 1.813-3.879 4.126-3.878 2.305 0 4.07 1.623 4.104 3.776zm-1.728-.055c0-1.344-1.03-2.36-2.396-2.36-1.355 0-2.377.998-2.378 2.32 0 1.345 1.03 2.36 2.397 2.36z"></path>
                        </g>
                        <g>
                          <path
                            fill="#231f20"
                            d="M69.608 63.261l.003-5.97-1.699-.001-.002 7.492 5.75.001v-1.52z"></path>
                        </g>
                        <g>
                          <path
                            fill="#231f20"
                            d="M5.75 64.762H0l.001-7.492H1.7l-.001 5.97 4.053.002z"></path>
                        </g>
                        <g>
                          <path
                            fill="#231f20"
                            d="M43.649 62.264l-.306.271c-.583.514-1.152.858-2.043.857-1.33 0-2.372-1.048-2.37-2.386 0-1.34 1.019-2.347 2.371-2.347.74 0 1.334.242 1.981.811l.32.281 1.164-1.155-.322-.28c-.7-.61-1.566-1.176-3.132-1.176-2.306-.002-4.112 1.714-4.112 3.904 0 2.204 1.753 3.867 4.08 3.868 1.61 0 2.528-.658 3.216-1.302l.312-.294z"></path>
                        </g>
                        <g>
                          <path
                            fill="#231f20"
                            d="M49.985 21.736c-.089 7.83-1.09 14.305-1.57 17.217-.197 1.195-.287 1.313-1.56 1.501-.575.084-1.096.145-1.593.185l-.008.002c-.496.046-1.3.05-1.3.05s-.806-.004-1.302-.05l-.006-.001c-.498-.04-1.02-.101-1.598-.186-1.273-.188-1.363-.306-1.56-1.5-.482-2.913-1.481-9.387-1.57-17.218L37.9 20.63l.018-1.106v-.001c.089-7.83 1.088-14.305 1.57-17.217.197-1.194.287-1.313 1.56-1.5.64-.095 1.214-.161 1.762-.2v.001A21.284 21.284 0 0 1 43.952.57s.649.003 1.14.037V.604c.548.04 1.122.106 1.762.2 1.273.188 1.363.307 1.56 1.501.48 2.912 1.481 9.387 1.57 17.217v.001c.004.306.003.279.017 1.106zM44.752 1.112c.24 6.34.34 16.41.34 16.984 0 1.235.139 1.642 1.792 1.734.824.046 2.112.15 2.774.197-.054-.187-.105-.241-.19-.277-.17-.07-.29-.106-.561-.206-.141-.052-.224-.103-.222-.234v-.063h-.002c-.132-8.043-1.416-14.32-1.571-15.065-.21-1.007-.472-1.034-1.242-1.132a19.464 19.464 0 0 0-.414-.049h-.001c-.097-.015-.125-.043-.156-.241-.06-.387-.095-.574-.216-1.302zm-5.534 18.135h-.002v.063c.002.131-.081.182-.221.234-.27.1-.39.135-.561.206-.085.035-.134.09-.188.274.73-.052 1.941-.147 2.772-.194 1.649-.092 1.787-.5 1.787-1.734 0-.54.104-10.636.34-16.984-.308.064-.33.346-.33.346-.122.728-.156.915-.217 1.302-.031.2-.059.227-.159.24v.001c-.136.015-.27.03-.414.05-.768.097-1.03.124-1.239 1.13zm3.938 20.903c-.241-6.29-.342-16.406-.342-16.987 0-1.235-.138-1.641-1.79-1.734-.78-.044-1.95-.132-2.777-.19.053.181.103.235.187.27.171.071.292.106.562.207.14.052.224.102.222.233v.063h.002c.132 8.043 1.416 14.32 1.571 15.066.21 1.006.472 1.033 1.241 1.13.145.02.28.035.415.05v.002c.101.013.129.04.16.24.06.388.095.574.217 1.303zm5.535-18.137l.002-.001v-.063c-.002-.13.081-.181.222-.233.272-.101.391-.136.563-.207.085-.035.136-.09.19-.275-.828.058-1.989.15-2.78.195-1.656.093-1.795.5-1.795 1.734 0 .582-.1 10.711-.34 16.986.307-.066.33-.346.33-.346.122-.729.157-.915.217-1.302.031-.199.06-.227.156-.24l.001-.003c.136-.015.27-.03.415-.05.772-.097 1.034-.124 1.245-1.13z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </StyledGrid>
            </StyledContainer>
          </StyledBox>
        </StyledFooter>
      )}
      {/* <SessionErrorDialog /> */}
      <SessionTimeOutPopup />
      <ConfirmationDialog />
    </>
  );
}

export default withRouter(React.memo(Footer));
