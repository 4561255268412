/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONS from "../action-types/account";
import initStates from "./initStates";
import { LOGOUT_SUCCESS_ACTION } from "../actions/user";

/**
 * Account reducer
 * handles states used by account related components
 * @param state State object managed by account reducer
 * @param action The dispatched action
 */
const accountReducer = createReducer(initStates.account, (builder) => {
  const data = {
    lastName: "lastname",
    registrationStatus: "RegisteredPerson",
    preferredLanguage: "en_US",
    resourceId:
      "https://localhost:443/wcs/resources/store/11/person/@self?responseFormat=application%2Fjson&langId=-1",
    preferredCurrency: "USD",
    distinguishedName:
      "uid=b2cshopper1@mailinator.com,o=default organization,o=root organization",
    orgizationId: "-2000",
    addressId: "3074457354533579480",
    accountStatus: "Enabled",
    email1: "b2cshopper1@mailinator.com",
    profileType: "Consumer",
    contact: [
      {
        firstName: "John",
        lastName: "Doe",
        zipCode: "12345",
        country: "US",
        email1: "billing@mailinator.com",
        city: "Schenectady",
        nickName: "billing-joe",
        addressType: "Billing",
        state: "NY",
        addressLine: ["1 Sesame Street", "", ""],
        addressId: "3074457354534345997",
        primary: "false",
      },
      {
        lastName: "Smith",
        zipCode: "54321",
        country: "US",
        city: "NYC",
        nickName: "shipping-jane",
        addressType: "Shipping",
        addressLine: ["1 Main Street", "Unit #2", ""],
        addressId: "3074457354535646440",
        phone1: "4161234567",
        firstName: "Jane",
        email1: "shipping@mailinator.com",
        state: "NV",
        primary: "false",
      },
    ],
    challengeQuestion: "-",
    nickName: "b2cshopper1@mailinator.com",
    addressType: "ShippingAndBilling",
    resourceName: "person",
    userId: "2",
    registrationDateTime: "2020-04-30T18:33:52.680Z",
    organizationDistinguishedName: "o=default organization,o=root organization",
    firstName: "firstname",
    logonId: "b2cshopper1@mailinator.com",
    lastUpdate: "2020-04-30T18:33:52.680Z",
    registrationApprovalStatus: "Approved",
    passwordExpired: "false",
    primary: "true",
  };

  builder.addCase(ACTIONS.ORDERS_GET_SUCCESS, (state, action: AnyAction) => {
    state.orders = action.response;
  });
  builder.addCase(
    ACTIONS.ADDRESS_DETAILS_GET_SUCCESS,
    (state, action: AnyAction) => {
      const response = action.response;

      let newPerson = { ...response };
      if (response) {
        let contactMap = {};
        const contactList = response.contact;
        if (contactList) {
          contactList.forEach((address: any, index: number) => {
            if (address && address.addressId) {
              contactMap[address.addressId] = address;
            }
          });

          const { contact, ...person } = response;

          newPerson = {
            ...person,
            data,
            contactMap,
            contactList,
          };
        }
        state.address = newPerson;
      }
    }
  );
  builder.addCase(LOGOUT_SUCCESS_ACTION, (state, action: AnyAction) => {
    state.address = data;
    state.orders = null;
  });
});

export default accountReducer;
