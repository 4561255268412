import React from 'react';
import { useEffect, useState } from 'react';
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { getSite } from "../../_foundation/hooks/useSite";
import { useLocation} from 'react-router-dom';
import HTMLReactParser from "html-react-parser";

const OrderDetailTable=(props)=>{
  const siteInfo = getSite();
  const [orderHistoryThumbnails, setorderHistoryThumbnails] = useState<any | []>([]);
  const location = useLocation();
  const URL = window.location.href
  const extractBaseURL = URL.replace(location.pathname,"")
  const baseUrl = extractBaseURL.replace("/"+siteInfo?.storeName,"")
  const [checkDisable, setcheckDisable] = useState<boolean>(false);
  
  useEffect(() => {
    if(props.partNumber != ""){
      let requestOptions = {
        method: 'GET',
        headers: new Headers()
      };
      let partId = props.partNumber.replaceAll("-", "")
      fetch(baseUrl+`${siteInfo?.searchContext}/store/${siteInfo?.storeID}/productview/byPartNumbers?partNumber=`+partId+`&profileName=HCL_findProductByPartNumber_Summary`, 
      requestOptions)
      .then(response => response.json())
      .then((result) => {
        setorderHistoryThumbnails(result.catalogEntryView)
      })
      .catch(error => console.log('error', error));
    }

    setcheckDisable(props.diasable)
  }, [props]);
      
  return(
    <StyledGrid xs={12}>
      <div className="order__details">
        {/* {!orderHistoryThumbnails && (
          <CircularProgress color="inherit" />
        )} */}
        {orderHistoryThumbnails && orderHistoryThumbnails.map((value: any) => (
          <>
            {checkDisable ? (
              <>
                <a className="disableTag" >
                  <img src={value.thumbnail} width="150" height="150" />
                  <div className="order__details-text">
                    <p className="order__product-title">{value.partNumber}</p>
                    <p className="order__product-text">{HTMLReactParser(value.name)}</p>
                    {props.showQuantity ? <p>Qty: {props.selectedQuantity}</p> : null}

                  </div>
                </a>
              </>
            ) : (
              <>
                <a href={value.seo.href} target="_blank" >
                  <img src={value.thumbnail} width="150" height="150" />
                  <div className="order__details-text">
                    <p className="order__product-title">{value.partNumber}</p>
                    <p className="order__product-text">{HTMLReactParser(value.name)}</p>
                    {props.showQuantity ? <p>Qty: {props.selectedQuantity}</p> : null}

                  </div>
                </a>
              </>
            )}
            
          </>
        ))}
      </div>
    </StyledGrid>

  )
}

export default OrderDetailTable