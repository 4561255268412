//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import * as ACTIONS from "../action-types/wishList";
import { WISHLIST_RESET_ACTION } from "../actions/wishList";

/**
 * Wish List Reducer
 * handles states used by wish list related components
 * @param state State object managed by wish list reducer
 * @param action The dispatched action
 */
const WishListReducer = createReducer(initStates.wishlist, (builder) => {
  builder.addCase(ACTIONS.WISHLIST_GET_SUCCESS, (state, action: AnyAction) => {
    state.list = action.response.GiftList;
  });
  builder.addCase(WISHLIST_RESET_ACTION, (state, action: AnyAction) => {
    state.list = null;
  });
});
export default WishListReducer;
