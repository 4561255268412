//Services
import { log } from "console";
import cartService from "../apis/transaction/cart.service";
import UADataService from "../gtm/ua/uaData.service";
import axios from "axios";
import { useState } from "react";
import { slice, toLower } from "lodash-es";
declare var _satellite: any;

const loadADTM = (callback) => {
  var subdomain = (window as any).location.hostname?.split(".")[0],
    url;
  if (subdomain.indexOf("accessories") !== -1 || subdomain.indexOf("prod") !== -1) {
    url =
      "https://assets.adobedtm.com/248d63c2ff6b/38fb2d0e721e/launch-5a5594a90029.min.js";
  } else {
    url =
      "https://assets.adobedtm.com/248d63c2ff6b/38fb2d0e721e/launch-de3f4653194a-staging.min.js";
  }
  const existingScript = document.getElementById("adobedtm");
  if (!existingScript && url) {
    const script = document.createElement("script");
    script.src = url;
    script.id = "adobedtm";
    script.className = "optanon-category-6";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const adobeService = {
  async track(parameters: any) {
    // Find Viewport using userAgent
    var viewPort;
    if (/android|iphone/i.test(navigator.userAgent)) {
      viewPort = "mobile";
    } else if (/Tablet|ipad/i.test(navigator.userAgent)) {
      viewPort = "Tablet";
    } else {
      viewPort = "PC";
    }

    //
    // const publicUrlPath = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '';
    // User Detail
    let currentUser: any = localStorage.getItem(
      "HCS-FordUSLincoln-currentUser"
    );
    const currentUserObj: any = JSON.parse(currentUser);
    // console.log("11111 currentUserObj",currentUserObj)
    // Vehicle Detail
    let vehicleDetail: any = localStorage.getItem("myGarage");
    let vehicleDetailObj = JSON.parse(vehicleDetail);

    let details: any = await UADataService.sendHomePageViewEvent({});
    // let cartDetails: any = await UADataService.sendCartPageViewEvent({});
    let checkoutLandingPageDetails: any =
      await UADataService.sendCheckoutPageViewEvent({});

    var digitaldata: any = {};
    digitaldata.page = {
      pageName: "la:" + parameters?.pageName,
      siteSection: parameters?.siteSection,
      hierarchy: "la:" + parameters?.hierarchy,
      userLanguage: "eng",
      pageNameNoVehicle: "la:" + parameters?.pageName,
      noProduct: parameters.noProduct,
      client: "lincoln",
      site: "accessories.lincoln.com",
      radUIVersion: "ui:rad:" + toLower(viewPort),
      variantName: parameters?.variantName,
      tool: parameters?.tool,
      referredTo: parameters?.referredTo,
      siteSearchString: parameters?.siteSearchString,
      searchDepth: parameters?.searchDepth,
    };
    digitaldata.user = {
      loginStatus:
        currentUserObj && currentUserObj?.userLoggedIn
          ? "logged in"
          : "logged out",
      registeredStatus:
        currentUserObj && !currentUserObj?.isGuest
          ? "registered"
          : "non-registered",
    };
    digitaldata.vehicle = {
      garageNameplate: vehicleDetailObj?.model, //capture vehicle nameplate if the user already has a vehicle in garage.
      garageMY: vehicleDetailObj?.year, //capture vehicle MY if in garage
    };
    // if (details !== undefined && details.pagePath === publicUrlPath) {
    if (parameters.eventType === "searchCatalog") {
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters.onClickLinkName,
        onclick: parameters.onClick,
        tool: "",
      };
      digitaldata.event = {
        action: "tool",
      };
      digitaldata.vehicle = {
        modelYear: vehicleDetailObj?.year, //capture vehicle nameplate if the user already has a vehicle in garage.
        nameplate: vehicleDetailObj?.name, //capture vehicle MY if in garage
      };
    } else if (parameters.eventType === "registerConfirmation") {
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters.eventType === "findDelaer") {
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters.eventType === "findDelaerResult") {
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters?.onclickLinkName,
        onclick: parameters?.onclick,
        tool: "",
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters.eventType === "cartLanding") {
      let cartDetailsData: any = await cartService?.getCart({});
      const cartProductInfo =
        cartDetailsData?.data?.orderItem?.length > 0
          ? cartDetailsData?.data?.orderItem
          : [];
      const cartProductDiscount =
        cartDetailsData?.data?.promotionCode?.length > 0
          ? cartDetailsData?.data?.promotionCode
          : [];
      // deliveryMethod: deliveryMethod: val.shipModeDescription
      let delMethod = cartProductInfo?.map(
        (val: any) => val.shipModeDescription
      );

      // Remove duplicates using a Set
      let uniqueSet = new Set(delMethod);

      // Convert Set back to an array
      let arrdeliverymethod = Array.from(uniqueSet);
      console.log("cartProductInfo", cartProductInfo);
      digitaldata.cart = {
        step: "scView",
        orderNumber: "no-order-number",
        subTotalNoDiscount: parseFloat(
          cartDetailsData?.data?.grandTotal
        ).toFixed(2),
        subTotal: parseFloat(cartDetailsData?.data?.grandTotal).toFixed(2),
        discountAmount: parseFloat(cartDetailsData?.data?.totalAdjustment)
          .toFixed(2)
          .slice(1),
        shippingCost: parseFloat(
          cartDetailsData?.data?.totalShippingCharge
        ).toFixed(2),
        // subTotalDiscounted : parseFloat(cartDetailsData?.data?.grandTotal).toFixed(2) - parseFloat(cartDetailsData?.data?.totalAdjustment).toFixed(2),
        taxes: parseFloat(cartDetailsData?.data?.totalSalesTax).toFixed(2),
        cartTotal: parseFloat(cartDetailsData?.data?.grandTotal).toFixed(2),
        discountCode: "no-discount-code",
        deliveryMethod: arrdeliverymethod.join(","),
        productInfo: cartProductInfo?.map((val: any, i) => ({
          SKU: val.partNumber,

          quantity: parseInt(val?.quantity),
          unitPrice: parseFloat(val.unitPrice).toFixed(2),
          namePlate: "",
          modelYear: "",
          name: "",
          category: "",
          subCategory: "",
          installationType: "",
          availability: val?.orderItemInventoryStatus,
          discountCode: "no-discount-code",
          unitPriceDiscounted: 0,
          unitDiscountAmout:
            val?.adjustment && val?.adjustment > 0
              ? val?.adjustment?.map((discountAmtVal: any) => ({
                  unitDiscountAmout: parseFloat(discountAmtVal?.value).toFixed(
                    2
                  ),
                }))
              : 0,
          totalInstallationCost: "",
          totalCost: val?.orderItemPrice,
        })),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters.eventType === "cartCheckout") {
      const cartInfo: any = await cartService.getCart({});
      const cartProductInfo =
        cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];

      //discount Amount calculation
      const discountAMTArray =
        cartInfo?.data?.adjustment?.length > 0
          ? cartInfo.data.adjustment.filter((f) => f.code.includes("off"))
          : 0;
      const discountAMT = discountAMTArray[0]?.amount.slice(1);
      const discountAMTVal =
        typeof discountAMT === "undefined" ? 0 : discountAMT;

      //FPR Amount calculation
      const fordRedeemPointsUsedArray =
        cartInfo?.data?.adjustment?.length > 0
          ? cartInfo.data.adjustment.filter((f) => f.code.includes("FPR"))
          : 0;
      const fordRedeemPointsUsed =
        fordRedeemPointsUsedArray[0]?.amount.slice(1);
      const fordRedeemPointsUsedVal =
        typeof fordRedeemPointsUsed === "undefined" ? 0 : fordRedeemPointsUsed;
      // console.log("11111 discountAMT",discountAMTVal,fordRedeemPointsUsedVal)
      // console.log(" 11111 cartProductInfo cartCheckout", cartInfo);

      // deliveryMethod: deliveryMethod: val.shipModeDescription
      let delMethod = cartProductInfo?.map(
        (val: any) => val.shipModeDescription
      );

      // Remove duplicates using a Set
      let uniqueSet = new Set(delMethod);

      // Convert Set back to an array
      let arrdeliverymethod = Array.from(uniqueSet);

      let subtotalcalculation = (
        parseFloat(cartInfo?.data?.totalProductPrice) -
        parseFloat(discountAMTVal)
      ).toString();

      console.log(
        "cartProductInfo delMethod",
        delMethod,
        "arrdeliverymethod",
        arrdeliverymethod
      );
      digitaldata.cart = {
        step: "scView",
        orderNumber: "no-order-number",
        subTotalNoDiscount: parseFloat(
          cartInfo?.data?.totalProductPrice
        ).toFixed(2),
        subTotal: parseFloat(cartInfo?.data?.totalProductPrice).toFixed(2),
        subTotalDiscounted: parseFloat(subtotalcalculation).toFixed(2),
        discountAmount: discountAMTVal,
        shippingCost: cartInfo?.data?.totalShippingCharge,
        taxes:
          parseFloat(cartInfo?.data?.totalSalesTax) +
          parseFloat(cartInfo?.data?.totalShippingTax),
        fpRewards: fordRedeemPointsUsedVal,
        cartTotal: cartInfo?.data?.grandTotal,
        discountCode:
          cartInfo?.data?.promotionCode &&
          cartInfo?.data?.promotionCode?.length > 0
            ? cartInfo?.data?.promotionCode?.map((discountVal: any) => ({
                discountCode: discountVal?.code,
              }))
            : "no-discount-code",
        deliveryMethod: arrdeliverymethod.join(","),
        productInfo: cartProductInfo?.map((val: any, i) => ({
          SKU: val.partNumber,
          quantity: parseInt(val?.quantity),
          unitPrice: parseFloat(val?.unitPrice).toFixed(2),
          namePlate: "",
          modelYear: "",
          name: parameters?.name,
          category: "",
          subCategory: "",
          installationType: "",
          availability: val?.orderItemInventoryStatus,
          discountCode:
            cartInfo?.data?.promotionCode &&
            cartInfo?.data?.promotionCode?.length > 0
              ? cartInfo?.data?.promotionCode?.map((discountVal: any) => ({
                  discountCode: discountVal?.code,
                }))
              : "no-discount-code",
          // unitPriceDiscounted:(val?.totalAdjustment?.value.length>0)?(val?.totalAdjustment?.value).slice(1):'0',
          // unitDiscountAmout: (val?.adjustment && val?.adjustment>0)?val?.adjustment?.map((disAmountVal: any) => ({ unitDiscountAmout: parseFloat(disAmountVal?.amount).toFixed(2)})):0,
          unitPriceDiscounted:
            val?.totalAdjustment?.value.length > 0
              ? parseFloat(val.unitPrice) -
                (val?.totalAdjustment?.value).slice(1) / parseInt(val?.quantity)
              : "0",
          unitDiscountAmout:
            val?.totalAdjustment?.value.length > 0
              ? (val?.totalAdjustment?.value).slice(1) / parseInt(val?.quantity)
              : "0",
          totalInstallationCost: "",
          totalCost: val?.orderItemPrice,
          deliveryMethod: val.shipModeDescription,
        })),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters?.eventType === "checkoutShipping") {
      const cartDetailsData = await cartService.getCart({});
      const cartProductInfo =
        cartDetailsData?.data?.orderItem?.length > 0
          ? cartDetailsData?.data?.orderItem
          : [];

      //discount Amount calculation
      const discountAMTArray =
        cartDetailsData?.data?.adjustment?.length > 0
          ? cartDetailsData.data.adjustment.filter((f) =>
              f.code.includes("off")
            )
          : 0;
      const discountAMT = discountAMTArray[0]?.amount.slice(1);
      const discountAMTVal =
        typeof discountAMT === "undefined" ? 0 : discountAMT;

      //FPR Amount calculation
      const fordRedeemPointsUsedArray =
        cartDetailsData?.data?.adjustment?.length > 0
          ? cartDetailsData.data.adjustment.filter((f) =>
              f.code.includes("FPR")
            )
          : 0;
      const fordRedeemPointsUsed =
        fordRedeemPointsUsedArray[0]?.amount.slice(1);
      const fordRedeemPointsUsedVal =
        typeof fordRedeemPointsUsed === "undefined" ? 0 : fordRedeemPointsUsed;

      // console.log("11111 discountAMT",discountAMTVal,fordRedeemPointsUsedVal)
      // deliveryMethod: deliveryMethod: val.shipModeDescription
      let delMethod = cartProductInfo?.map(
        (val: any) => val.shipModeDescription
      );

      // Remove duplicates using a Set
      let uniqueSet = new Set(delMethod);

      // Convert Set back to an array
      let arrdeliverymethod = Array.from(uniqueSet);

      // console.log("11111 cartProductInfo checkoutShipping", cartDetailsData);
      let subtotalcalculation = (
        parseFloat(cartDetailsData?.data?.totalProductPrice) -
        parseFloat(discountAMTVal)
      ).toString();
      digitaldata.cart = {
        step: "scView",
        orderNumber: "no-order-number",
        subTotalNoDiscount: parseFloat(
          cartDetailsData?.data?.totalProductPrice
        ).toFixed(2),
        subTotal: parseFloat(cartDetailsData?.data?.totalProductPrice).toFixed(
          2
        ),
        subTotalDiscounted: parseFloat(subtotalcalculation).toFixed(2),
        fpRewards: fordRedeemPointsUsedVal,
        discountAmount: discountAMTVal,
        shippingCost: cartDetailsData?.data?.totalShippingCharge,
        taxes:
          parseFloat(cartDetailsData?.data?.totalSalesTax) +
          parseFloat(cartDetailsData?.data?.totalShippingTax),
        cartTotal: cartDetailsData?.data?.grandTotal,
        discountCode:
          cartDetailsData?.data?.promotionCode &&
          cartDetailsData?.data?.promotionCode?.length > 0
            ? cartDetailsData?.data?.promotionCode?.map((discountVal: any) => ({
                discountCode: discountVal?.code,
              }))
            : "no-discount-code",
        deliveryMethod: arrdeliverymethod.join(","),
        productInfo: cartProductInfo?.map((val: any, i) => ({
          SKU: val.partNumber,
          quantity: parseInt(val?.quantity),
          unitPrice: parseFloat(val.unitPrice).toFixed(2),
          namePlate: "",
          modelYear: "",
          name: parameters?.name,
          category: "",
          subCategory: "",
          installationType: "",
          availability: val?.orderItemInventoryStatus,
          discountCode:
            cartDetailsData?.data?.promotionCode &&
            cartDetailsData?.data?.promotionCode?.length > 0
              ? cartDetailsData?.data?.promotionCode?.map(
                  (discountVal: any) => ({ discountCode: discountVal?.code })
                )
              : "no-discount-code",
          // unitPriceDiscounted:(val?.totalAdjustment?.value.length>0)?(val?.totalAdjustment?.value).slice(1):'0',
          // unitDiscountAmout: (val?.adjustment && val?.adjustment>0)? val?.adjustment?.map((disAmountVal: any) => ({ unitDiscountAmout: parseFloat(disAmountVal?.amount).toFixed(2)})):0,
          unitPriceDiscounted:
            val?.totalAdjustment?.value.length > 0
              ? parseFloat(val.unitPrice) -
                (val?.totalAdjustment?.value).slice(1) / parseInt(val?.quantity)
              : "0",
          unitDiscountAmout:
            val?.totalAdjustment?.value.length > 0
              ? (val?.totalAdjustment?.value).slice(1) / parseInt(val?.quantity)
              : "0",
          totalInstallationCost: "",
          totalCost: val?.orderItemPrice,
        })),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };

      return digitaldata;
    } else if (parameters?.eventType === 'checkoutrewardsanddiscounts') {
      const cartInfo = await cartService.getCart({});
      const cartProductInfo = cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];

      //FPR Amount calculation
      const fordRedeemPointsUsedArray = cartInfo?.data?.adjustment?.length>0? (cartInfo.data.adjustment.filter((f)=>f.code.includes("FPR"))):0;
      const fordRedeemPointsUsed = fordRedeemPointsUsedArray[0]?.amount.slice(1);
      const fordRedeemPointsUsedVal = typeof(fordRedeemPointsUsed) === "undefined" ? 0 : fordRedeemPointsUsed
      // console.log("11111 discountAMT",discountAMTVal,fordRedeemPointsUsedVal)
      //console.log(" 11111 cartProductInfo cartCheckout", cartInfo);

      //discount Amount calculation
      const discountAMTArray = cartInfo?.data?.totalAdjustment;
      // const discountAMT = discountAMTArray[0]?.amount.slice(1);
      const discountAMT = typeof(discountAMTArray) === "undefined" ? 0 : discountAMTArray;
      const discountAMTT = ((parseFloat(fordRedeemPointsUsedVal) + parseFloat(discountAMT)).toFixed(2)).toString();
      const discountAMTVal = discountAMTT.slice(1);
     // console.log(" 11111 cartProductInfo cartPayment", cartProductInfo, "discountAMT" ,discountAMT,"discountAMTT",discountAMTT , "discountAMTVal" ,discountAMTVal);

      let subtotalcalculation = (parseFloat(cartInfo?.data?.totalProductPrice)-parseFloat(discountAMTVal)).toString()
      // console.log("11111 cartProductInfo",cartProductInfo)
      // deliveryMethod: deliveryMethod: val.shipModeDescription
      let delMethod =cartProductInfo?.map((val: any) =>(val.shipModeDescription) );

      // Remove duplicates using a Set
      let uniqueSet = new Set(delMethod);

      // Convert Set back to an array
      let arrdeliverymethod = Array.from(uniqueSet);
      //console.log("cartProductInfo",cartInfo)
      digitaldata.cart = {
          step: "scView",
          orderNumber: 'no-order-number',
          subTotalNoDiscount: parseFloat(cartInfo?.data?.totalProductPrice).toFixed(2),
          subTotal: parseFloat(cartInfo?.data?.totalProductPrice).toFixed(2),
          subTotalDiscounted :parseFloat(subtotalcalculation).toFixed(2),
          fpRewards:fordRedeemPointsUsedVal,
          discountAmount: discountAMTVal,
          shippingCost: cartInfo?.data?.totalShippingCharge,
          taxes: cartInfo?.data?.totalSalesTax,
          cartTotal: cartInfo?.data?.grandTotal,
          discountCode: (cartInfo?.data?.promotionCode && cartInfo?.data?.promotionCode?.length > 0)?cartInfo?.data?.promotionCode?.map((discountVal: any) => ({ discountCode: discountVal?.code })): 'no-discount-code',
          deliveryMethod: arrdeliverymethod.join(','),
          productInfo: cartProductInfo?.map((val: any, i) => ({
              SKU: val.partNumber,
              quantity: parseInt(val?.quantity),
              unitPrice: parseFloat(val.unitPrice).toFixed(2),
              namePlate: '',
              modelYear: '',
              name: parameters?.name,
              category: '',
              subCategory: '',
              installationType: '',
              availability: val?.orderItemInventoryStatus,
              discountCode: (cartInfo?.data?.promotionCode && cartInfo?.data?.promotionCode?.length > 0)?cartInfo?.data?.promotionCode?.map((discountVal: any) => ({ discountCode: discountVal?.code })): 'no-discount-code',
              unitPriceDiscounted: (val?.totalAdjustment?.value.length>0)?parseFloat(val.unitPrice)-((val?.totalAdjustment?.value).slice(1))/(parseInt(val?.quantity)):'0',
              unitDiscountAmount:(val?.totalAdjustment?.value.length>0)?(((val?.totalAdjustment?.value).slice(1))/(parseInt(val?.quantity))):"0",
              totalInstallationCost: '',
              totalCost: val?.orderItemPrice
          }))
      };
      digitaldata.event = {
          action: parameters?.hierarchy,
      };

    } else if (parameters?.eventType === "checkoutPayment") {
      const cartInfo = await cartService.getCart({});
      const cartProductInfo =
        cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];

      //discount Amount calculation
      const discountAMTArray =
        cartInfo?.data?.adjustment?.length > 0
          ? cartInfo.data.adjustment.filter((f) => f.code.includes("off"))
          : 0;
      const discountAMT = discountAMTArray[0]?.amount.slice(1);
      const discountAMTVal =
        typeof discountAMT === "undefined" ? 0 : discountAMT;

      //FPR Amount calculation
      const fordRedeemPointsUsedArray =
        cartInfo?.data?.adjustment?.length > 0
          ? cartInfo.data.adjustment.filter((f) => f.code.includes("FPR"))
          : 0;
      const fordRedeemPointsUsed =
        fordRedeemPointsUsedArray[0]?.amount.slice(1);
      const fordRedeemPointsUsedVal =
        typeof fordRedeemPointsUsed === "undefined" ? 0 : fordRedeemPointsUsed;
      let subtotalcalculation = (
        parseFloat(cartInfo?.data?.totalProductPrice) -
        parseFloat(discountAMTVal)
      ).toString();
      // console.log("11111 cartProductInfo checkoutPayment", cartProductInfo);
      // deliveryMethod: deliveryMethod: val.shipModeDescription
      let delMethod = cartProductInfo?.map(
        (val: any) => val.shipModeDescription
      );

      // Remove duplicates using a Set
      let uniqueSet = new Set(delMethod);

      // Convert Set back to an array
      let arrdeliverymethod = Array.from(uniqueSet);
      console.log("cartProductInfo", cartInfo);
      digitaldata.cart = {
        step: "scView",
        orderNumber: "no-order-number",
        subTotalNoDiscount: parseFloat(
          cartInfo?.data?.totalProductPrice
        ).toFixed(2),
        subTotal: parseFloat(cartInfo?.data?.totalProductPrice).toFixed(2),
        subTotalDiscounted: parseFloat(subtotalcalculation).toFixed(2),
        fpRewards: fordRedeemPointsUsedVal,
        discountAmount: discountAMTVal,
        shippingCost: cartInfo?.data?.totalShippingCharge,
        taxes:
          parseFloat(cartInfo?.data?.totalSalesTax) +
          parseFloat(cartInfo?.data?.totalShippingTax),
        cartTotal: cartInfo?.data?.grandTotal,
        discountCode:
          cartInfo?.data?.promotionCode &&
          cartInfo?.data?.promotionCode?.length > 0
            ? cartInfo?.data?.promotionCode?.map((discountVal: any) => ({
                discountCode: discountVal?.code,
              }))
            : "no-discount-code",
        deliveryMethod: arrdeliverymethod.join(","),
        productInfo: cartProductInfo?.map((val: any, i) => ({
          SKU: val.partNumber,
          quantity: parseInt(val?.quantity),
          unitPrice: parseFloat(val.unitPrice).toFixed(2),
          namePlate: "",
          modelYear: "",
          name: parameters?.name,
          category: "",
          subCategory: "",
          installationType: "",
          availability: val?.orderItemInventoryStatus,
          discountCode:
            cartInfo?.data?.promotionCode &&
            cartInfo?.data?.promotionCode?.length > 0
              ? cartInfo?.data?.promotionCode?.map((discountVal: any) => ({
                  discountCode: discountVal?.code,
                }))
              : "no-discount-code",
          unitPriceDiscounted:
            val?.totalAdjustment?.value.length > 0
              ? parseFloat(val.unitPrice) -
                (val?.totalAdjustment?.value).slice(1) / parseInt(val?.quantity)
              : "0",
          unitDiscountAmout:
            val?.totalAdjustment?.value.length > 0
              ? (val?.totalAdjustment?.value).slice(1) / parseInt(val?.quantity)
              : "0",
          totalInstallationCost: "",
          totalCost: val?.orderItemPrice,
        })),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters?.eventType === "productDetails") {
      // const cartInfo = await cartService.getCart({});
      // const cartProductInfo = cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];
      // .availability[0]?.inventoryStatus === true?"Available" :"not-Available"
      // console.log("11111", parameters);
      digitaldata.cart = {
        step: "scOpen",
        orderNumber: "no-order-number",
        subTotalNoDiscount: 0,
        subTotal: 0,
        discountAmount: 0,
        shippingCost: 0,
        taxes: 0,
        cartTotal: 0,
        discountCode: "no-discount-code",
        deliveryMethod: "no-delivery-method",
        productInfo: {
          SKU: parameters.SKU,
          quantity: 1,
          unitPrice: parseFloat(parameters?.unitPrice).toFixed(2),
          name: parameters?.name,
          category: "",
          subCategory: "",
          installationType: "",
          availability: parameters?.buyableFlag ? "Available" : "not-Available",
          discountCode: "no-discount-code",
          unitPriceDiscounted: 0,
          unitDiscountAmount: 0,
          totalInstallationCost: parameters?.totalInstallationCost,
          totalCost: 0,
        },
      };
    } else if (parameters?.eventType === "addToCart") {
      const cartInfo = await cartService.getCart({});
      const cartProductInfo =
        cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];
      const qty = parameters.quantityty;
      // console.log("11111 _orderItems", parameters?._orderItems[0]?.quantity);
      // console.log("11111 parameters quantity", parameters.quantityty);
      // console.log("11111 cartProductInfo", cartProductInfo);
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters.onclickLinkName,
        onclick: parameters.onclick,
      };
      digitaldata.cart = {
        step: "scAdd, scOpen",
        orderNumber: "no-order-number",
        subTotalNoDiscount: 0,
        subTotal: 0,
        discountAmount: 0,
        shippingCost: 0,
        taxes: 0,
        cartTotal: 0,
        discountCode: "no-discount-code",
        deliveryMethod: "no-delivery-method",
        // productInfo:(cartProductInfo && cartProductInfo?.length > 0) ({
        //     SKU: parameters?._orderItems[0]?.partNumber,
        //     quantity: parameters.quantityty,
        //     unitPrice: cartProductInfo?.filter((val)=>val.partNumber === parameters?.PartNumber?(parseFloat(val?.unitPrice).toFixed(2)):""),
        //     name: parameters?.name,
        //     category: '',
        //     subCategory: '',
        //     installationType: '',
        //     availability: 'In-Stock',
        //     discountCode: 'no-discount-code',
        //     unitPriceDiscounted: 0,
        //     unitDiscountAmount: 0,
        //     totalInstallationCost: 0,
        //     totalCost: 0,
        // })
        productInfo: (cartProductInfo && cartProductInfo?.length > 0
          ? cartProductInfo
          : []
        )
          .filter(
            (val: any) =>
              val.partNumber === parameters?._orderItems[0]?.partNumber
          )
          .map((val: any, i) => ({
            SKU: val.partNumber,
            quantity: qty,
            unitPrice: parseFloat(val?.unitPrice).toFixed(2),
            name: parameters?.name,
            category: "",
            subCategory: "",
            installationType: "",
            availability: "Available",
            discountCode: "no-discount-code",
            unitPriceDiscounted: 0,
            unitDiscountAmount: 0,
            totalInstallationCost: 0,
            totalCost: 0,
          })),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters?.eventType === "updateCart") {
      const cartInfo = await cartService.getCart({});
      const cartProductInfo =
        cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];
      console.log(
        "cartProductInfo",
        cartProductInfo,
        "item",
        parameters.item,
        "quantity",
        parameters.quantity
      );
      // deliveryMethod: deliveryMethod: val.shipModeDescription
      let delMethod = cartProductInfo?.map(
        (val: any) => val.shipModeDescription
      );

      // Remove duplicates using a Set
      let uniqueSet = new Set(delMethod);

      // Convert Set back to an array
      let arrdeliverymethod = Array.from(uniqueSet);
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters?.onclickLinkName,
        onclick: parameters?.onclick,
      };
      digitaldata.cart = {
        step: "scAdd",
        // productInfo: cartProductInfo?.map((val: any) => ({
        //     SKU: val.partNumber,
        //     quantity: parseInt(val.quantity),
        //     unitPrice: parseFloat(val.unitPrice).toFixed(2),
        //     name: parameters?.name,
        // }))
        orderNumber: "no-order-number",
        subTotalNoDiscount: "",
        subTotal: "",
        discountAmount: "",
        shippingCost: cartInfo?.data?.totalShippingCharge,
        taxes: cartInfo?.data?.totalSalesTax,
        cartTotal: "",
        discountCode:
          cartInfo?.data?.promotionCode &&
          cartInfo?.data?.promotionCode?.length > 0
            ? cartInfo?.data?.promotionCode?.map((discountVal: any) => ({
                discountCode: discountVal?.code,
              }))
            : "no-discount-code",
        deliveryMethod: arrdeliverymethod.join(","),
        productInfo: cartProductInfo
          ?.filter((val) => val.orderItemId === parameters?.orderItemId)
          .map((val: any, i) => ({
            SKU: val.partNumber,
            quantity:
              parameters?.item?.productId === val.productId &&
              parameters.item.orderItemPrice === val?.orderItemPrice
                ? parseInt(parameters?.quantity)
                : parseInt(val?.quantity),
            unitPrice: parseFloat(val.unitPrice).toFixed(2),
            namePlate: "",
            modelYear: "",
            name: parameters?.name,
            category: "",
            subCategory: "",
            installationType: "",
            availability: val?.orderItemInventoryStatus,
            discountCode:
              cartInfo?.data?.promotionCode &&
              cartInfo?.data?.promotionCode?.length > 0
                ? cartInfo?.data?.promotionCode?.map((discountVal: any) => ({
                    discountCode: discountVal?.code,
                  }))
                : "no-discount-code",
            unitPriceDiscounted: "",
            unitDiscountAmout:
              cartProductInfo?.adjustment &&
              cartProductInfo?.adjustment?.length > 0
                ? cartProductInfo?.adjustment?.map((disAmountVal: any) => ({
                    unitDiscountAmout: parseFloat(disAmountVal?.amount).toFixed(
                      2
                    ),
                  }))
                : 0,
            totalInstallationCost: "",
            totalCost:
              val.unitPrice *
              (parameters?.item?.productId === val.productId &&
              parameters.item.orderItemPrice === val?.orderItemPrice
                ? parseInt(parameters?.quantity)
                : parseInt(val?.quantity)),
          })),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters?.eventType === "removeCart") {
      const cartInfo = await cartService.getCart({});
      const cartProductInfo =
        cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];
      // console.log("11111 removeCart", cartProductInfo);

      // deliveryMethod: deliveryMethod: val.shipModeDescription
      let delMethod = cartProductInfo?.map(
        (val: any) => val.shipModeDescription
      );

      // Remove duplicates using a Set
      let uniqueSet = new Set(delMethod);

      // Convert Set back to an array
      let arrdeliverymethod = Array.from(uniqueSet);
      console.log(
        "cartProductInfo",
        cartProductInfo,
        "item",
        parameters.item,
        "quantity",
        parameters.quantity
      );
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters?.onclickLinkName,
        onclick: parameters?.onclick,
      };
      // if(cartProductInfo.length > 0){
      //     console.log("length >1")
      //     digitaldata.cart = {
      //         step: "scView",
      //         orderNumber: 'no-order-number1',
      //         subTotalNoDiscount: '',
      //         subTotal: '',
      //         discountAmount: '',
      //         shippingCost: cartInfo?.data?.totalShippingCharge,
      //         taxes: cartInfo?.data?.totalSalesTax,
      //         cartTotal: cartInfo?.data?.grandTotal,
      //         discountCode: (cartInfo?.data?.promotionCode && cartInfo?.data?.promotionCode?.length > 0)?cartInfo?.data?.promotionCode?.map((discountVal: any) => ({ discountCode: discountVal?.code })): 'no-discount-code',
      //         deliveryMethod: arrdeliverymethod.join(','),
      //         productInfo: cartProductInfo.filter((val)=>val.orderItemId == parameters?.orderItemId)?.map((val: any, i) => ({
      //             SKU: val.partNumber,
      //             quantity: parameters?.item?.productId=== val?.productId && parameters.item.orderItemPrice === val?.orderItemPrice?parseInt(parameters?.quantity):parseInt(val?.quantity),
      //             unitPrice: parseFloat(val.unitPrice).toFixed(2),
      //             namePlate: '',
      //             modelYear: '',
      //             name: parameters?.name,
      //             category: '',
      //             subCategory: '',
      //             installationType: '',
      //             availability: val?.orderItemInventoryStatus,
      //             discountCode: (cartInfo?.data?.promotionCode && cartInfo?.data?.promotionCode?.length > 0)?cartInfo?.data?.promotionCode?.map((discountVal: any) => ({ discountCode: discountVal?.code })):'no-discount-code',
      //             unitPriceDiscounted: '',
      //             unitDiscountAmout: (cartProductInfo?.adjustment && cartProductInfo?.adjustment?.length > 0)? cartProductInfo?.adjustment?.map((disAmountVal: any) => ({unitDiscountAmout: parseFloat(disAmountVal?.amount).toFixed(2)})): 0,
      //             totalInstallationCost: '',
      //             totalCost: val?.orderItemPrice
      //         }))
      //     }
      // }
      // else if(cartProductInfo.length == 0){
      // console.log("length == 0")
      digitaldata.cart = {
        step: "scView",
        orderNumber: "no-order-number",
        subTotalNoDiscount: "",
        subTotal: "",
        discountAmount: "",
        shippingCost: cartInfo?.data?.totalShippingCharge,
        taxes: cartInfo?.data?.totalSalesTax,
        cartTotal: cartInfo?.data?.grandTotal,
        discountCode:
          cartInfo?.data?.promotionCode &&
          cartInfo?.data?.promotionCode?.length > 0
            ? cartInfo?.data?.promotionCode?.map((discountVal: any) => ({
                discountCode: discountVal?.code,
              }))
            : "no-discount-code",
        deliveryMethod: arrdeliverymethod.join(","),
        productInfo: {
          SKU: parameters.SKU,
          quantity: parameters?.quantity,
          unitPrice: parseFloat(parameters.unitPrice).toFixed(2),
          namePlate: "",
          modelYear: "",
          name: parameters?.name,
          category: "",
          subCategory: "",
          installationType: "",
          availability: parameters.availability,
          discountCode:
            cartInfo?.data?.promotionCode &&
            cartInfo?.data?.promotionCode?.length > 0
              ? cartInfo?.data?.promotionCode?.map((discountVal: any) => ({
                  discountCode: discountVal?.code,
                }))
              : "no-discount-code",
          unitPriceDiscounted: "",
          unitDiscountAmout:
            cartProductInfo?.adjustment &&
            cartProductInfo?.adjustment?.length > 0
              ? cartProductInfo?.adjustment?.map((disAmountVal: any) => ({
                  unitDiscountAmout: parseFloat(disAmountVal?.amount).toFixed(
                    2
                  ),
                }))
              : 0,
          totalInstallationCost: "",
          totalCost: parameters.totalCost,
        },
      };

      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters?.eventType === "orderAgain") {
      // console.log("11111 orderagain inside tag")
      const cartInfo = await cartService.getCart({});
      const cartProductInfo =
        cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters?.onclickLinkName,
        onclick: parameters?.onclick,
      };
      digitaldata.cart = {
        step: "scAdd",
        productInfo: cartProductInfo?.map((val: any) => ({
          SKU: val.partNumber,
          quantity: parseInt(val.quantity),
          unitPrice: parseFloat(val.unitPrice).toFixed(2),
          name: parameters?.name,
        })),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters?.eventType === "checkoutThankyou") {
      // console.log("11111 checkoutThankyou",parameters.orderData)

      //discount Amount calculation
      const discountAMTArray =
        parameters.orderData?.adjustment?.length > 0
          ? parameters.orderData.adjustment.filter((f) =>
              f.code.includes("off")
            )
          : 0;
      const discountAMT = discountAMTArray[0]?.amount.slice(1);
      const discountAMTVal =
        typeof discountAMT === "undefined" ? 0 : discountAMT;

      //FPR Amount calculation
      const fordRedeemPointsUsedArray =
        parameters.orderDataa?.adjustment?.length > 0
          ? parameters.orderData.adjustment.filter((f) =>
              f.code.includes("FPR")
            )
          : 0;
      const fordRedeemPointsUsed =
        fordRedeemPointsUsedArray[0]?.amount.slice(1);
      const fordRedeemPointsUsedVal =
        typeof fordRedeemPointsUsed === "undefined" ? 0 : fordRedeemPointsUsed;
      let subtotalcalculation = (
        parseFloat(parameters?.orderData?.totalProductPrice) -
        parseFloat(discountAMTVal)
      ).toString();

      // deliveryMethod: deliveryMethod: val.shipModeDescription
      let delMethod = parameters?.orderData?.orderItem?.map(
        (val: any) => val.shipModeDescription
      );

      // Remove duplicates using a Set
      let uniqueSet = new Set(delMethod);

      // Convert Set back to an array
      let arrdeliverymethod = Array.from(uniqueSet);

      const unitDiscountAmountVal = parameters?.orderData?.orderItem?.map(
        (val: any) =>
          val?.adjustment && val.adjustment?.length > 0
            ? val.adjustment?.map((disAmountVal: any) => ({
                unitDiscountAmout: parseFloat(
                  disAmountVal?.amount.substring(1)
                ).toFixed(2),
              }))
            : 0
      );
      const unitPriceVal = parameters?.orderData?.orderItem?.map((val: any) =>
        parseFloat(val.unitPrice).toFixed(2)
      );

      digitaldata.cart = {
        step: "purchase",
        orderNumber: parameters?.orderData?.customerOrderNumber,
        subTotalNoDiscount: parseFloat(
          parameters?.orderData?.totalProductPrice
        ).toFixed(2),
        subTotal: parseFloat(parameters?.orderData?.totalProductPrice).toFixed(
          2
        ),
        subTotalDiscounted: (
          parseFloat(parameters?.orderData?.totalProductPrice) -
          parseFloat(parameters?.orderData?.totalAdjustment.substring(1))
        ).toString(),
        fpRewards: fordRedeemPointsUsedVal,
        discountAmount: discountAMTVal,
        shippingCost: parseFloat(
          parameters?.orderData?.totalShippingCharge
        ).toFixed(2),
        taxes:
          parseFloat(parameters?.orderData?.totalSalesTax) +
          parseFloat(parameters?.orderData?.totalShippingTax),
        cartTotal: parseFloat(parameters?.orderData?.grandTotal).toFixed(2),
        discountCode:
          parameters?.orderData?.promotionCode &&
          parameters?.orderData?.promotionCode.length > 0
            ? parameters?.orderData?.promotionCode?.map((discountVal: any) => ({
                discountCode: discountVal?.code,
              }))
            : "no-discount-code",
        deliveryMethod: arrdeliverymethod.join(","),
        productInfo: parameters?.orderData?.orderItem?.map(
          (val: any, i: any) => ({
            SKU: val.partNumber,
            quantity: parseInt(val?.quantity),
            unitPrice: parseFloat(val.unitPrice).toFixed(2),
            namePlate: "",
            modelYear: "",
            name: parameters?.imageSrcList[i]?.des,
            category: "",
            subCategory: "",
            installationType: "",
            availability: val?.orderItemInventoryStatus,
            discountCode:
              parameters?.orderData?.promotionCode &&
              parameters?.orderData?.promotionCode.length > 0
                ? parameters?.orderData?.promotionCode?.map(
                    (discountVal: any) => ({ discountCode: discountVal?.code })
                  )
                : "no-discount code",
            // unitPriceDiscounted:parseFloat(val.unitPrice).toFixed(2),
            // unitDiscountAmout: (val?.adjustment && val.adjustment?.length>0)?val.adjustment?.map((disAmountVal: any) => ({unitDiscountAmout: parseFloat(disAmountVal?.amount.substring(1)).toFixed(2)})):0,
            unitPriceDiscounted:
              val?.totalAdjustment?.value.length > 0
                ? parseFloat(val.unitPrice) -
                  (val?.totalAdjustment?.value).slice(1) /
                    parseInt(val?.quantity)
                : "0",
            unitDiscountAmout:
              val?.totalAdjustment?.value.length > 0
                ? (val?.totalAdjustment?.value).slice(1) /
                  parseInt(val?.quantity)
                : "0",
            totalInstallationCost: "0",
            totalCost: parseFloat(val?.orderItemPrice).toFixed(2),
          })
        ),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters.eventType === "globalNameplateLoad") {
      const cartInfo = await cartService.getCart({});
      const cartProductInfo =
        cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];
      digitaldata.vehicle = {
        namePlate: "",
        modelYear: "",
        garageNameplate: vehicleDetailObj?.year,
        garageMy: vehicleDetailObj?.name,
      };
      digitaldata.cart = {
        productInfo: cartProductInfo?.map((val: any, i) => ({
          SKU: val.partNumber,
          quantity: parseInt(val?.quantity),
          unitPrice: parseFloat(val.unitPrice).toFixed(2),
          name: parameters?.name,
          category: "",
          subCategory: "",
          availability: val?.orderItemInventoryStatus,
        })),
      };
    } else if (parameters.eventType === "globalOnClick") {
      /*  console.log("11111 edit address inside tag")
        const cartInfo = await cartService.getCart({});
      const cartProductDiscount =
        cartInfo?.data?.promotionCode &&
        cartInfo?.data?.promotionCode?.length > 0
          ? cartInfo?.data?.promotionCode
          : [];*/
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters?.onclickLinkName,
        onclick: parameters?.onclick,
      };
      /*digitaldata.cart = {
        deliveryMethod: cartInfo?.data?.orderItem?.map((val: any) => ({
          deliveryMethod: val.carrier,
        })),
        discountCode:
          cartInfo?.data?.promotionCode &&
          cartInfo?.data.promotionCode?.map((val: any) => ({
            discountCode: val.code,
          })),
        productInfo: cartInfo?.data?.orderItem?.map((val: any) => ({
          installationType: "",
          discountCode: cartProductDiscount?.map((discountVal: any) => ({
            discountCode: discountVal?.code,
          })),
        })),
      }; */
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    }
    // else if (parameters.eventType === 'globalOnclick') {
    //     console.log("11111 edit address inside tag")
    //     const cartInfo = await cartService.getCart({});
    //     const cartProductDiscount = cartInfo?.data?.promotionCode?.length > 0 ? cartInfo?.data?.promotionCode : [];
    //     digitaldata.onclick = {
    //         onclickLinkName: 'la:' + parameters?.onclickLinkName,
    //         onclick: parameters?.onclick,
    //     };
    //     digitaldata.cart = {
    //         deliveryMethod: cartInfo?.data?.orderItem?.map((val: any) => ({
    //             deliveryMethod: val.carrier,
    //         })),
    //         discountCode: cartInfo?.data.promotionCode?.map((val: any) => ({
    //             discountCode: val.code
    //         })),
    //         productInfo: cartInfo?.data?.orderItem?.map((val: any) => ({
    //             installationType: '',
    //             discountCode: cartProductDiscount?.map((discountVal: any) => ({ discountCode: discountVal?.code })),
    //         }))
    //     };
    // }
    else if (parameters.eventType === "referralExit") {
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters?.onClickLinkName,
        onclick: parameters?.onClickName,
        referredTo: parameters?.referredTo,
      };
      digitaldata.event = {
        action: "brand referral",
      };
    } else if (parameters.eventType === "genericOnclick") {
      // const cartInfo = await cartService.getCart({});
      // const cartProductInfo = cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];
      digitaldata.onclick = {
        onclickLinkName: "la:" + parameters?.onClickLinkName,
        onclick: parameters?.onClickName,
        referredTo: parameters?.referredTo,
        garageModelYear: parameters?.garageModelYear,
        garageNameplate: parameters?.garageNameplate,
      };
      digitaldata.cart = {
        // productInfo: {
        //     SKU:getProdDetailsOutOfStockAdobeTag[0],
        //     name: getProdDetailsOutOfStockAdobeTag[0],
        //     category: '',
        //     subCategory: '',
        //     availability: getProdDetailsOutOfStockAdobeTag[2],
        // }
      };
    } else if (parameters.eventType === "genericExit") {
      digitaldata.onclick = {
        onclickLinkName: parameters?.onClickLinkName,
        onclick: parameters?.onClickName,
      };
    } else if (parameters.eventType === "click-to-chat") {
      digitaldata.onclick = {
        onclickLinkName: "la:contact us:live chat ",
        onclick: "",
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters.eventType === "click-to-call") {
      digitaldata.onclick = {
        onclickLinkName: "la:click to call",
        onclick: "contact us:content action: click to call",
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };
    } else if (parameters?.eventType === 'Fmaloginsuccess') {
      digitaldata.onclick = {
          onclickLinkName: parameters?.onclickLinkName,
          onclick: parameters?.onclick,
      };
      digitaldata.user = {
          loginStatus: 'logged in',
          registeredStatus: "registered",
      };
      digitaldata.page = {
          pageName: 'la:' + parameters?.pageName,
          siteSection: parameters?.siteSection,
          hierarchy: parameters?.hierarchy,
          userLanguage: 'eng',
          pagenameNoProduct: 'la:' + parameters?.pagenameNoProduct,
          pageNameNoVehicle: "fma:login",
          referredTo: parameters?.referredTo,
          client: "lincoln",
          site: "accessories.lincoln.com",
          radUIVersion: 'ui:rad:' + toLower(viewPort),
          variantName: parameters?.variantName,
          tool: "event:logins",
          siteSearchString: parameters?.siteSearchString,
          searchDepth: parameters?.searchDepth,
      };
  }
  // console.log("111",digitaldata);
    return digitaldata;
  },

  callSatellite(result: any) {
    try {
      (window as any).digitaldata = result;
      loadADTM(() => {
        console.log(" Adobe tags service");
        (window as any)._satellite?.track(
          (window as any).digitaldata?.page?.variantName,
          result
        );
      });
    } catch (e) {
      console.log(" Could not connect to Adobe tags service", e);
    }
  },
};

export default adobeService;
