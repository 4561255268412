import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { useSite } from "../../../_foundation/hooks/useSite";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import categoryService from "../../../_foundation/apis/search/categories.service";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../../configs/catalog";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { LOCALE } from "../../../_foundation/constants/common";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";

const SiteMap = (props: any) => {
  const { mySite } = useSite();
  const contractId = useSelector(currentContractIdSelector);
  const translation = {
    siteMap: "Site Map",
  };
  const locale = localStorageUtil.get(LOCALE);
  const widgetName = getDisplayName(SiteMap);
  const [headerResponse, setHeaderResponse] = useState<any | []>([]);
  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const payload = {
    ...payloadBase,
  };

  useEffect(() => {
    if (mySite !== null && contractId !== undefined) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: contractId,
        },
        ...payload,
      };
      categoryService
        .getV2CategoryResourcesUsingGET(parameters)
        .then((res) => {
          setHeaderResponse(res.data.contents);
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, contractId, locale]);

  return (
    <div
      id="sitemapper"
      className="TnC-section"
      title="Lincoln accessories Sitemap"
      placeholder="Can't find what you're looking for? Review our sitemap for a list of our pages.">
      {/* <StyledTypography variant="h2" className="title--sm">{translation.siteMap}</StyledTypography> 

        {headerResponse && headerResponse.map((page: any, index: number) => (
          <ul className="sitemapper-list">
            <a href={window.location.origin + page.seo.href} >{page.name}</a>
            <li style={{display:'flex', flexWrap: 'wrap'}}>
              {page.children && page.children.map((category: any, index: number) => (
                <ul className="sitemapper-list__category">
                  <li>
                    <a href={window.location.origin + category.seo.href} >{category.name}</a>
                    {category.children && category.children.map((subCategory: any, index: number) => (
                        <li>
                          <a href={window.location.origin + subCategory?.seo?.href} >{subCategory?.name}</a>
                        </li>
                    ))}
                  </li>
                </ul>
              ))}
            </li>
          </ul>
        ))} */}

      {/* <h1>Lincoln accessories Sitemap</h1> */}
      <p>
        Can't find what you're looking for? Review our sitemap for a list of our
        pages.
      </p>
      <h2>Pages</h2>

      <ul className="sitemapper-list">
        {/* <li>
          <a
            title="Lincoln - Collection Landing - Highlight"
            href="https://accessories.lincoln.com/">
            Lincoln - Collection Landing - Highlight
          </a>
        </li> */}
        <li>
          <a
            title="Lincoln Aviator 2020"
            href="https://accessories.lincoln.com/lincoln-aviator-2020">
            Lincoln Aviator 2020
          </a>
        </li>
        <li>
          <a
            title="Lincoln Aviator 2021"
            href="https://accessories.lincoln.com/lincoln-aviator-2021">
            Lincoln Aviator 2021
          </a>
        </li>

        <li>
          <a
            title="Lincoln Continental 2017"
            href="https://accessories.lincoln.com/lincoln-continental-2017">
            Lincoln Continental 2017
          </a>
        </li>
        <li>
          <a
            title="Lincoln Continental 2018"
            href="https://accessories.lincoln.com/lincoln-continental-2018">
            Lincoln Continental 2018
          </a>
        </li>
        <li>
          <a
            title="Lincoln Continental 2019"
            href="https://accessories.lincoln.com/lincoln-continental-2019">
            Lincoln Continental 2019
          </a>
        </li>
        <li>
          <a
            title="Lincoln Continental 2020"
            href="https://accessories.lincoln.com/lincoln-continental-2020">
            Lincoln Continental 2020
          </a>
        </li>

        <li>
          <a
            title="Lincoln Corsair 2020"
            href="https://accessories.lincoln.com/lincoln-corsair-2020">
            Lincoln Corsair 2020
          </a>
        </li>
        <li>
          <a
            title="Lincoln Corsair 2021"
            href="https://accessories.lincoln.com/lincoln-corsair-2021">
            Lincoln Corsair 2021
          </a>
        </li>

        {/*<li>
          <a
            title="Lincoln Mark LT 2006"
            href="https://accessories.ford.com/lincoln-mark-lt-2006">
            Lincoln Mark LT 2006
          </a>
        </li>
        <li>
          <a
            title="Lincoln Mark LT 2007"
            href="https://accessories.ford.com/lincoln-mark-lt-2007">
            Lincoln Mark LT 2007
          </a>
        </li>
        <li>
          <a
            title="Lincoln Mark LT 2008"
            href="https://accessories.ford.com/lincoln-mark-lt-2008">
            Lincoln Mark LT 2008
          </a>
        </li>
        <li>
          <a
            title="Lincoln Mark LT 2009"
            href="https://accessories.ford.com/lincoln-mark-lt-2009">
            Lincoln Mark LT 2009
          </a>
        </li>
        <li>
          <a
            title="Lincoln Mark LT 2010"
            href="https://accessories.ford.com/lincoln-mark-lt-2010">
            Lincoln Mark LT 2010
          </a>
        </li>
        <li>
          <a
            title="Lincoln Mark LT 2011"
            href="https://accessories.ford.com/lincoln-mark-lt-2011">
            Lincoln Mark LT 2011
          </a>
        </li>
        <li>
          <a
            title="Lincoln Mark LT 2012"
            href="https://accessories.ford.com/lincoln-mark-lt-2012">
            Lincoln Mark LT 2012
          </a>
        </li>
        <li>
          <a
            title="Lincoln Mark LT 2013"
            href="https://accessories.ford.com/lincoln-mark-lt-2013">
            Lincoln Mark LT 2013
          </a>
        </li>
        <li>
          <a
            title="Lincoln Mark LT 2014"
            href="https://accessories.ford.com/lincoln-mark-lt-2014">
            Lincoln Mark LT 2014
          </a>
        </li>*/}

        <li>
          <a
            title="Lincoln MKC 2015"
            href="https://accessories.lincoln.com/lincoln-mkc-2015">
            Lincoln MKC 2015
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKC 2016"
            href="https://accessories.lincoln.com/lincoln-mkc-2016">
            Lincoln MKC 2016
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKC 2017"
            href="https://accessories.lincoln.com/lincoln-mkc-2017">
            Lincoln MKC 2017
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKC 2018"
            href="https://accessories.lincoln.com/lincoln-mkc-2018">
            Lincoln MKC 2018
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKC 2019"
            href="https://accessories.lincoln.com/lincoln-mkc-2019">
            Lincoln MKC 2019
          </a>
        </li>
        {/*<li>
          <a
            title="Lincoln MKS 2009"
            href="https://accessories.ford.com/lincoln-mks-2009">
            Lincoln MKS 2009
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKS 2010"
            href="https://accessories.ford.com/lincoln-mks-2010">
            Lincoln MKS 2010
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKS 2011"
            href="https://accessories.ford.com/lincoln-mks-2011">
            Lincoln MKS 2011
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKS 2012"
            href="https://accessories.ford.com/lincoln-mks-2012">
            Lincoln MKS 2012
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKS 2013"
            href="https://accessories.ford.com/lincoln-mks-2013">
            Lincoln MKS 2013
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKS 2014"
            href="https://accessories.ford.com/lincoln-mks-2014">
            Lincoln MKS 2014
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKS 2015"
            href="https://accessories.ford.com/lincoln-mks-2015">
            Lincoln MKS 2015
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKS 2016"
            href="https://accessories.ford.com/lincoln-mks-2016">
            Lincoln MKS 2016
          </a>
      </li> */}
        <li>
          <a
            title="Lincoln MKT 2010"
            href="https://accessories.lincoln.com/lincoln-mkt-2010">
            Lincoln MKT 2010
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2011"
            href="https://accessories.lincoln.com/lincoln-mkt-2011">
            Lincoln MKT 2011
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2012"
            href="https://accessories.lincoln.com/lincoln-mkt-2012">
            Lincoln MKT 2012
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2013"
            href="https://accessories.lincoln.com/lincoln-mkt-2013">
            Lincoln MKT 2013
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2014"
            href="https://accessories.lincoln.com/lincoln-mkt-2014">
            Lincoln MKT 2014
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2015"
            href="https://accessories.lincoln.com/lincoln-mkt-2015">
            Lincoln MKT 2015
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2016"
            href="https://accessories.lincoln.com/lincoln-mkt-2016">
            Lincoln MKT 2016
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2017"
            href="https://accessories.lincoln.com/lincoln-mkt-2017">
            Lincoln MKT 2017
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2018"
            href="https://accessories.lincoln.com/lincoln-mkt-2018">
            Lincoln MKT 2018
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKT 2019"
            href="https://accessories.lincoln.com/lincoln-mkt-2019">
            Lincoln MKT 2019
          </a>
        </li>
        {/*<li>
          <a
            title="Lincoln MKX 2007"
            href="https://accessories.ford.com/lincoln-mkx-2007">
            Lincoln MKX 2007
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2008"
            href="https://accessories.ford.com/lincoln-mkx-2008">
            Lincoln MKX 2008
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2009"
            href="https://accessories.ford.com/lincoln-mkx-2009">
            Lincoln MKX 2009
          </a>
        </li>
        */}
        <li>
          <a
            title="Lincoln MKX 2010"
            href="https://accessories.lincoln.com/lincoln-mkx-2010">
            Lincoln MKX 2010
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2011"
            href="https://accessories.lincoln.com/lincoln-mkx-2011">
            Lincoln MKX 2011
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2012"
            href="https://accessories.lincoln.com/lincoln-mkx-2012">
            Lincoln MKX 2012
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2013"
            href="https://accessories.lincoln.com/lincoln-mkx-2013">
            Lincoln MKX 2013
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2014"
            href="https://accessories.lincoln.com/lincoln-mkx-2014">
            Lincoln MKX 2014
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2015"
            href="https://accessories.lincoln.com/lincoln-mkx-2015">
            Lincoln MKX 2015
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2016"
            href="https://accessories.lincoln.com/lincoln-mkx-2016">
            Lincoln MKX 2016
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2017"
            href="https://accessories.lincoln.com/lincoln-mkx-2017">
            Lincoln MKX 2017
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKX 2018"
            href="https://accessories.lincoln.com/lincoln-mkx-2018">
            Lincoln MKX 2018
          </a>
        </li>
        {/*<li>
          <a
            title="Lincoln MKZ 2007"
            href="https://accessories.ford.com/lincoln-mkz-2007">
            Lincoln MKZ 2007
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2008"
            href="https://accessories.ford.com/lincoln-mkz-2008">
            Lincoln MKZ 2008
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2009"
            href="https://accessories.lincoln.com/lincoln-mkz-2010">
            Lincoln MKZ 2009
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2010"
            href="https://accessories.lincoln.com/lincoln-mkz-2011">
            Lincoln MKZ 2010
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2011"
            href="https://accessories.lincoln.com/lincoln-mkz-2012">
            Lincoln MKZ 2011
          </a>
        </li>*/}
        <li>
          <a
            title="Lincoln MKZ 2012"
            href="https://accessories.lincoln.com/lincoln-mkz-2013">
            Lincoln MKZ 2012
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2013"
            href="https://accessories.lincoln.com/lincoln-mkz-2014">
            Lincoln MKZ 2013
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2014"
            href="https://accessories.lincoln.com/lincoln-mkz-2015">
            Lincoln MKZ 2014
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2015"
            href="https://accessories.lincoln.com/lincoln-mkz-2016">
            Lincoln MKZ 2015
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2016"
            href="https://accessories.lincoln.com/lincoln-mkz-2017">
            Lincoln MKZ 2016
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2017"
            href="https://accessories.lincoln.com/lincoln-mkz-2018">
            Lincoln MKZ 2017
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2018"
            href="https://accessories.lincoln.com/lincoln-mkz-2019">
            Lincoln MKZ 2018
          </a>
        </li>
        <li>
          <a
            title="Lincoln MKZ 2019"
            href="https://accessories.lincoln.com/lincoln-mkz-2020">
            Lincoln MKZ 2019
          </a>
        </li>
        {/* <li>
          <a
            title="Lincoln MKZ 2020"
            href="https://accessories.lincoln.com/lincoln-mkz-2021">
            Lincoln MKZ 2020
          </a>
        </li> */}

        <li>
          <a
            title="Lincoln Nautilus 2019"
            href="https://accessories.lincoln.com/lincoln-nautilus-2019">
            Lincoln Nautilus 2019
          </a>
        </li>
        <li>
          <a
            title="Lincoln Nautilus 2020"
            href="https://accessories.lincoln.com/lincoln-nautilus-2020">
            Lincoln Nautilus 2020
          </a>
        </li>
        {/*<li>
          <a
            title="Lincoln Nautilus 2021"
            href="https://accessories.lincoln.com/lincoln-nautilus-2021">
            Lincoln Nautilus 2021
          </a>
        </li>*/}

        {/* <li>
          <a
            title="Lincoln Navigator 2006"
            href="https://accessories.lincoln.com/lincoln-navigator-2006">
            Lincoln Navigator 2006
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2007"
            href="https://accessories.lincoln.com/lincoln-navigator-2007">
            Lincoln Navigator 2007
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2008"
            href="https://accessories.lincoln.com/lincoln-navigator-2008">
            Lincoln Navigator 2008
          </a>
        </li>*/}
        <li>
          <a
            title="Lincoln Navigator 2009"
            href="https://accessories.lincoln.com/lincoln-navigator-2009">
            Lincoln Navigator 2009
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2010"
            href="https://accessories.lincoln.com/lincoln-navigator-2010">
            Lincoln Navigator 2010
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2011"
            href="https://accessories.lincoln.com/lincoln-navigator-2011">
            Lincoln Navigator 2011
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2012"
            href="https://accessories.lincoln.com/lincoln-navigator-2012">
            Lincoln Navigator 2012
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2013"
            href="https://accessories.lincoln.com/lincoln-navigator-2013">
            Lincoln Navigator 2013
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2014"
            href="https://accessories.lincoln.com/lincoln-navigator-2014">
            Lincoln Navigator 2014
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2015"
            href="https://accessories.lincoln.com/lincoln-navigator-2015">
            Lincoln Navigator 2015
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2016"
            href="https://accessories.lincoln.com/lincoln-navigator-2016">
            Lincoln Navigator 2016
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2017"
            href="https://accessories.lincoln.com/lincoln-navigator-2017">
            Lincoln Navigator 2017
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2018"
            href="https://accessories.lincoln.com/lincoln-navigator-2018">
            Lincoln Navigator 2018
          </a>
        </li>
        {/* <li>
          <a
            title="Lincoln Navigator 2019"
            href="https://accessories.lincoln.com/lincoln-navigator-2019">
            Lincoln Navigator 2019
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2020"
            href="https://accessories.lincoln.com/lincoln-navigator-2020">
            Lincoln Navigator 2020
          </a>
        </li>
        <li>
          <a
            title="Lincoln Navigator 2021"
            href="https://accessories.lincoln.com/lincoln-navigator-2021">
            Lincoln Navigator 2021
          </a>
        </li> */}

        {/* <li>
          <a
            title="Lincoln Town Car 2010"
            href="https://accessories.ford.com/lincoln-town-car-2010">
            Lincoln Town Car 2010
          </a>
        </li>
        <li>
          <a
            title="Lincoln Town Car 2011"
            href="https://accessories.ford.com/lincoln-town-car-2011">
            Lincoln Town Car 2011
          </a>
        </li> */}
      </ul>

      <h2>Collection</h2>

      <ul className="sitemapper-list">
        <li>
          <a
            title="Bed/Cargo Area"
            href="https://accessories.lincoln.com/bed-cargo-area-products">
            Bed/Cargo Area
          </a>
        </li>
        <li>
          <a
            title="Bed Covers"
            href="https://accessories.lincoln.com/lincoln-bed-covers">
            Bed Covers
          </a>
        </li>
        <li>
          <a
            title="Cargo Area Products"
            href="https://accessories.lincoln.com/lincoln-cargo-area-products">
            Cargo Area Products
          </a>
        </li>
        <li>
          <a
            title="Liners and Mats"
            href="https://accessories.lincoln.com/lincoln-liners-and-mats">
            Liners and Mats
          </a>
        </li>
        <li>
          <a title="Tents" href="https://accessories.lincoln.com/lincoln-tents">
            Tents
          </a>
        </li>

        <li>
          <a
            title="Electronics"
            href="https://accessories.lincoln.com/lincoln-electronics">
            Electronics
          </a>
        </li>
        <li>
          <a title="Audio" href="https://accessories.lincoln.com/lincoln-audio">
            Audio
          </a>
        </li>
        <li>
          <a
            title="Dashcam"
            href="https://accessories.lincoln.com/lincoln-dashcam">
            Dashcam
          </a>
        </li>
        <li>
          <a
            title="Keyless Entry"
            href="https://accessories.lincoln.com/lincoln-keyless-entry">
            Keyless Entry
          </a>
        </li>
        <li>
          <a
            title="Lamps, Lights and Treatments"
            href="https://accessories.lincoln.com/lincoln-lamps-lights-and-treatments">
            Lamps, Lights and Treatments
          </a>
        </li>
        <li>
          <a
            title="Parking Assist System"
            href="https://accessories.lincoln.com/lincoln-parking-assist-system">
            Parking Assist System
          </a>
        </li>
        <li>
          <a
            title="Performance"
            href="https://accessories.lincoln.com/lincoln-performance">
            Performance
          </a>
        </li>
        <li>
          <a
            title="Rear Seat Entertainment"
            href="https://accessories.lincoln.com/lincoln-rear-seat-entertainment">
            Rear Seat Entertainment
          </a>
        </li>
        <li>
          <a
            title="Remote Start and Vehicle Security"
            href="https://accessories.lincoln.com/lincoln-vehicle-security">
            Remote Start and Vehicle Security
          </a>
        </li>

        <li>
          <a
            title="Exterior"
            href="https://accessories.lincoln.com/lincoln-exterior">
            Exterior
          </a>
        </li>
        <li>
          <a
            title="Covers, Deflectors, and Protectors"
            href="https://accessories.lincoln.com/lincoln-covers-and-protectors">
            Covers, Deflectors, and Protectors
          </a>
        </li>
        <li>
          <a title="Fuel" href="https://accessories.lincoln.com/lincoln-fuel">
            Fuel
          </a>
        </li>
        <li>
          <a
            title="Hitches, Towing and Recovery"
            href="https://accessories.lincoln.com/lincoln-trailer-towing">
            Hitches, Towing and Recovery
          </a>
        </li>
        <li>
          <a
            title="Racks and Carriers"
            href="https://accessories.lincoln.com/lincoln-racks-and-carriers">
            Racks and Carriers
          </a>
        </li>
        <li>
          <a
            title="Running Boards, Step Bars and Rock Rails"
            href="https://accessories.lincoln.com/lincoln-running-boards-and-step-bars">
            Running Boards, Step Bars and Rock Rails
          </a>
        </li>
        <li>
          <a
            title="Splash Guards"
            href="https://accessories.lincoln.com/lincoln-splash-guards">
            Splash Guards
          </a>
        </li>

        <li>
          <a
            title="Interior"
            href="https://accessories.lincoln.com/lincoln-interior">
            Interior
          </a>
        </li>
        <li>
          <a
            title="Ash or Coin Cup"
            href="https://accessories.lincoln.com/lincoln-ash-or-coin-cup">
            Ash or Coin Cup
          </a>
        </li>
        <li>
          <a
            title="Comfort and Convenience"
            href="https://accessories.lincoln.com/lincoln-comfort-convenience">
            Comfort and Convenience
          </a>
        </li>
        <li>
          <a
            title="Floor Mats"
            href="https://accessories.lincoln.com/lincoln-floor-mats">
            Floor Mats
          </a>
        </li>
        <li>
          <a
            title="Safety/Emergency Kits"
            href="https://accessories.lincoln.com/lincoln-safety-emergency-kits">
            Safety/Emergency Kits
          </a>
        </li>
        <li>
          <a
            title="Seat Covers"
            href="https://accessories.lincoln.com/lincoln-seat-covers">
            Seat Covers
          </a>
        </li>

        <li>
          <a
            title="Wheels"
            href="https://accessories.lincoln.com/lincoln-wheels">
            Wheels
          </a>
        </li>
        <li>
          <a title="Locks" href="https://accessories.lincoln.com/lincoln-locks">
            Locks
          </a>
        </li>
        {/* <li>
          <a title="Wheels" href="https://accessories.lincoln.comundefined">
            Wheels
          </a>
        </li> */}
      </ul>

      <ul className="sitemapper-list"></ul>
    </div>
  );
};

export default SiteMap;
