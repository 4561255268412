/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
// Get current user's wish list
export const WISHLIST_GET_REQUIRED = "WISHLIST_GET_REQUIRED";
export const WISHLIST_GET_REQUESTED = "WISHLIST_GET_REQUESTED";
export const WISHLIST_GET_SUCCESS = "WISHLIST_GET_SUCCESS";
export const WISHLIST_GET_ERROR = "WISHLIST_GET_ERROR";
export const WISHLIST_RESET = "WISHLIST_RESET";
