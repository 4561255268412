import { FETCH_LIST, IF_SUCCESS, IF_ERROR } from "../action-types/product-list";

export interface PostsState {
  loading: boolean;
  productList: any;
  error: string | null;
}
const initialState: PostsState = {
  loading: false,
  productList: {},
  error: null,
};

export const productListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST:
      return {
        ...state,
        loading: true,
      };
    case IF_SUCCESS:
      //console.log("ReducerAction---", action.response);
      return {
        ...state,
        loading: false,
        productList: action.response,
        error: null,
      };
    case IF_ERROR:
      return {
        ...state,
        loading: false,
        productList: [],
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
