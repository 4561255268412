/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Redux
import { RootReducerState } from "../reducers";

const currentMakeSelector = (state: RootReducerState) => {
  return state.searchNow;
};

const currentVehiclesSelector = (state: RootReducerState) => {
  return state.vehicles;
};
export { currentMakeSelector, currentVehiclesSelector };
