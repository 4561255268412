/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
//Standard libraries
import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface MiniCartProps {
  closeMinicart?:Function;
}
const EmptyCart: React.FC<MiniCartProps> = (props: any) => {
  const closeEmptyCart= props.closeMinicart
  return (
    <div className="cart__vue-container">
      <section className="cart-main cart-empty">
        <div className="cart-main__container">
          <div className="cart-list__empty-text text-align-center">
            <p>Your cart is currently empty.</p>
            <Link to="/" onClick={closeEmptyCart}className="btn cartbutton">
              Return To Home
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmptyCart;
