import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import "./DisclosureStyle.scss";
import eventBus from "./../../utils/event";
import DisclosureEspot from "./DisclosureEspot";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);

function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        alert("You clicked outside of me!");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Disclosure = () => {
  const classes = useStyles();
  let [expanded, setExpanded] = useState(false);
  const aboutSection: any = useRef(null);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const location = useLocation();
  const [productListResponse, setProductListResponse] = useState("");

  const [displayHeader, setDisplayHeader] = React.useState<any>(true);
  React.useEffect(() => {
    let value;
    value =
      location.pathname.includes("checkout") ||
      location.pathname.includes("order-confirmation")
        ? false
        : true;
    setDisplayHeader(value);
  }, [location]);

  React.useEffect(()=>{
      eventBus.on("scrollToDisclosure", (data) => {
        setExpanded(true);
      })
  }, [])

  React.useEffect(() => {
    eventBus.on("clickOffer", (data) => {
      setExpanded(true);
      let top: any = aboutSection?.current;
      if (top && top?.offsetTop) {
        aboutSection?.current?.scrollIntoView({ behavior: "smooth" });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = () => {
    expanded = !expanded ? true : false;
    setExpanded(expanded);
  };

  const AccordionSummary = withStyles({
    content: {
      flexGrow: 0,
    },
  })(MuiAccordionSummary);

  return (
    displayHeader && (
      <div style={{marginTop:'10px'}}>
      <div className={`${classes.root} disclosures`} ref={aboutSection}>
        <Accordion
          onChange={() => {
            onChange();
          }}
          expanded={expanded}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="disclosures__trigger-area">
          <AccordionSummary
            className="disclosures__trigger-area"
            expandIcon={
              <ExpandMoreIcon
                className="disclosures__trigger-text-icon"
                aria-hidden="false"
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ width: "1150px" }}>
            <Typography
              className="DisclosureText"
              id="ScrollToDisclosureElement">
              Disclosure{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="Disclosures__content disclosures__content" style={{display: expanded ? 'block' : 'none'}}>
              <DisclosureEspot />
          </AccordionDetails>
        </Accordion>
      </div>
      </div>
    )
  );
};

export default Disclosure;
