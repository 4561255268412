/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import axios from "axios";
import { getSite } from "../hooks/useSite";

var qs = require("qs");
const rewardsService = {

  // this method is used for HTTP GET method
  getRewards() {
    const siteInfo = getSite();
    const url = `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/rewards/get`;
    var config: any = {
      method: "get",
      url: url,
    };

    return axios(config).catch(function (error) {
      console.log(error);
    });
  },
};

export default rewardsService;
