/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import AdobeService from "../adobeService/adobeService" 


const LincolnAdobeTags = () => {
  const  location  = useLocation(); 

  React.useEffect(() => { 
      if ((location.pathname === ROUTES.CHECKOUT_PAYMENT || location.pathname === ROUTES.CHECKOUT_PAYMENT_OLD) && location.search === '') {
        (async () => {
          let res = await AdobeService.track({ pageName: "cart:checkout:payment", variantName: "cart-checkout", siteSection: "checkout", hierarchy: "cart:checkout:checkout", step: "scView", tool: "checkout payment", eventType: "checkoutPayment",});
          AdobeService.callSatellite(res);
         //console.log("11111 Payment Page");
        })();
      }else if (location.pathname === ROUTES.CART || location.pathname === ROUTES.CART_OLD) {
        
        (async () => {
          let res = await AdobeService.track({ pageName: "cart:cart view", variantName: "cart-view", siteSection: "cart", hierarchy: "cart:cart view", eventType: "cartLanding",});
          AdobeService.callSatellite(res);
          // console.log("11111 cartLanding",location.pathname,ROUTES.CART);
        })();
      }else if (location.pathname === ROUTES.CHECKOUT_INFORMATION || location.pathname === ROUTES.CHECKOUT_INFORMATION_OLD) {
        
        (async () => {
          let res = await AdobeService.track({ pageName: "cart:checkout:landing page", variantName: "cart-checkout-landing", siteSection: "checkout", hierarchy: "cart:checkout:checkout", eventType: "cartCheckout",});
          AdobeService.callSatellite(res);
          // console.log("11111 CheckoutLanding",location.pathname,ROUTES.CHECKOUT_LANDING);
        })();
      }else if (location.pathname === ROUTES.CHECKOUT_SHIPPING || location.pathname === ROUTES.CHECKOUT_SHIPPING_OLD) {
        
        (async () => {
          let res = await AdobeService.track({ pageName: "cart:check out:shipping", variantName: "cart-checkout", siteSection: "checkout", hierarchy: "cart:checkout:checkout", eventType: "checkoutShipping",});
          AdobeService.callSatellite(res);
          // console.log("11111 CheckoutShipping",location.pathname,ROUTES.CHECKOUT_SHIPPING);
        })();
      }  else if (location.pathname === ROUTES.CHECKOUT_REWARDS) {
        (async () => {
        let res = await AdobeService.track({pageName: 'cart:checkout:rewards and discounts', variantName: 'cart-checkout', siteSection: 'checkout', pagenameNoProduct:"cart:checkout:rewards and discounts", hierarchy: 'cart:checkout:checkout', step: 'scView', tool: 'checkout rewards and discounts', eventType: 'checkoutrewardsanddiscounts'});
        AdobeService.callSatellite(res);
        })() 
      }  else if(localStorage.getItem('loginSuccessful') && localStorage.getItem('loginSuccessful') === 'true') {
        setTimeout(() => {
          (async () => {
            let res = await AdobeService.track({pageName: `fma:login`, variantName: "fma-login-complete", onclickLinkName: "fma:login:complete", onclick:'login:complete', siteSection: "fma", hierarchy: "fma:login", eventType: "Fmaloginsuccess"});
            AdobeService.callSatellite(res);
          })(); 
        },200);
        localStorage.removeItem('loginSuccessful');
      }
  }, [location]);

  return null;
}

export default LincolnAdobeTags;