/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/wishList";

const GET_USER_WISHLIST_ACTION = createAction<any>(
  ACTIONTYPES.WISHLIST_GET_REQUESTED
);
const WISHLIST_RESET_ACTION = createAction(ACTIONTYPES.WISHLIST_RESET);

export { GET_USER_WISHLIST_ACTION, WISHLIST_RESET_ACTION };
