/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
import {
  emeraldTheme as Emerald,
  sapphireTheme as Sapphire,
  lincolnTheme as LincolnTheme,
} from "@hcl-commerce-store-sdk/react-component";
const themes = { Emerald, Sapphire, LincolnTheme };
//const CurrentTheme = themes[process.env.REACT_APP_STORENAME || "Emerald"];
const CurrentTheme = themes["LincolnTheme"];
export { CurrentTheme };
