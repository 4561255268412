/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import * as ACTIONS from "../action-types/search-now";

/**
 * search now reducer
 */
const vehiclesReducer = createReducer(initStates.vehicles, (builder) => {

  builder.addCase(
    ACTIONS.FETCH_VEHICLES_REQUESTED,
    (state: any, action: AnyAction) => {
      state.default = null;
      state.all = [];
    }
  );

  builder.addCase(
    ACTIONS.FETCH_VEHICLES_SUCCESS,
    (state: any, action: AnyAction) => {
      const response = action.response;
      state.default = response.default;
      state.all = response.all;
    }
  );
});
export default vehiclesReducer;
