import { call, put } from "redux-saga/effects";
//Foundation libraries
import mockServerService from "../../../_foundation/apis/mock/mockserver.service";
import { getSite } from "../../../_foundation/hooks/useSite";
//Redux
import * as ACTIONS from "../../action-types/product-list";

export function* getproductlistWorks(action: any) {
  try {
    const siteInfo = getSite();
    const payload = action.payload;
    const response = yield call(
      mockServerService.getMock,
      {},
      {},
      `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/product-listing`
    );

    yield put({
      payload: payload,
      type: ACTIONS.IF_SUCCESS,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: ACTIONS.IF_ERROR, response: error });
  }
}
