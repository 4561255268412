/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import i18n from "../../../i18n";
import { userIdSelector } from "../../../redux/selectors/user";
import { CURRENT_USER } from "../../constants/common";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { localStorageUtil } from "../../utils/storageUtil";

const mygarageService = {
  addVehicle(data): AxiosPromise<any> {
    const siteInfo = getSite();
    const url = `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/managevehicle/add`;
    let requestOptions: AxiosRequestConfig = {
      method: "POST",
      url: url,
      data,
    };
    return axios(requestOptions);
  },
  deleteVehicle(vehicleId): AxiosPromise<any> {
    const siteInfo = getSite();
    const url = `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/managevehicle/delete?vehicleId=${vehicleId}`;
    let requestOptions: AxiosRequestConfig = {
      method: "DELETE",
      url: url,
    };
    return axios(requestOptions);
  },
  updateVehicle(data): AxiosPromise<any> {
    const siteInfo = getSite();
    const url = `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/managevehicle/modify`;
    let requestOptions: AxiosRequestConfig = {
      method: "POST",
      url: url,
      data,
    };
    return axios(requestOptions);
  },
  getVehicles(): AxiosPromise<any> {
    const siteInfo = getSite();
    const url = `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/managevehicle/listall`;
    let requestOptions: AxiosRequestConfig = {
      method: "GET",
      url: url,
    };
    return axios(requestOptions);
  },
  getVinLookup(vin): AxiosPromise<any> {
    const siteInfo = getSite();
    const url = `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/vehicleinfo/get?vin=${vin}`;
    //const url = `https://live.us.garage.ford.eastsideapps.io/api/v1/lookup/vin?vin=${vin}`;
    let requestOptions: AxiosRequestConfig = {
      method: "GET",
      url: url
    };
    return axios(requestOptions);
  }
};

export default mygarageService;
