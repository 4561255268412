/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { find } from "lodash-es";
import { call, put } from "redux-saga/effects";
//Foundation libraries
import mockServerService from "../../../_foundation/apis/mock/mockserver.service";
import { getSite } from "../../../_foundation/hooks/useSite";
//Redux
import * as ACTIONS from "../../action-types/search-now";
import mygarageService from "../../../_foundation/apis/transaction/mygarage.service";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { CURRENT_USER } from "../../../_foundation/constants/common";

export function* fetchModels(action: any) {
  try {
    const siteInfo = getSite();
    const payload = action.payload;
    //console.log("siteInfo", payload);
    const response = yield call(
      mockServerService.getMock,
      {},
      {},
      `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/makemodelyear/getMakeModelYear?langId=-1`

    );
    let models: any = [];
    let years = {};
    if (payload.make) {
      const makeData = response.data.make[payload.make];
      let tmpModelsObj = makeData.model;
      models = Object.keys(tmpModelsObj).map((key) => {
        years[key.trim()] = tmpModelsObj[key].years;
        return key.trim();

      });
    }
    models.sort((a, b) => a.localeCompare(b));
    yield put({
      payload: payload,
      type: ACTIONS.FETCH_MODEL_YEAR_SUCCESS,
      response: { models, years },
    });
  } catch (error) {
    yield put({ type: ACTIONS.FETCH_MODEL_YEAR_ERROR, error });
  }
}
function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}
export function* fetchVehicles(action) {
  
  
  try {
    let res: any = {
      default: null,
      all: [],
    }
    
    if(localStorageUtil.get(CURRENT_USER) && localStorageUtil.get(CURRENT_USER)?.userLoggedIn) {
      const response = yield call(
        mygarageService.getVehicles

      );
      res.all = response?.data?.vehicleList;
      if (response && response?.data && response?.data?.vehicleList) {
        res.default = find(response?.data?.vehicleList, (vc) => vc?.isDefault === "1");
        if (res.default) {
          const { make, model, year } = res.default;
          localStorage.setItem("myGarage", JSON.stringify({ make, model, year }));
          localStorage.setItem("currentVehicle", `${capitalizeFirstLetter(make)}_${model}_${year}`);
        } else {
          localStorage.removeItem("myGarage");
          localStorage.removeItem("currentVehicle");
        }
      }
    } else {
      if (localStorage.getItem('myGarage')) {
        const df: any = localStorage.getItem('myGarage');
        res.default = JSON.parse(df);
        res.all = [df];
      }

    }
    yield put({
      payload: {},
      type: ACTIONS.FETCH_VEHICLES_SUCCESS,
      response: res
    });
  } catch (error) {
    yield put({ type: ACTIONS.FETCH_VEHICLES_ERROR, error });
  }
}