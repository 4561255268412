/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const WC_PREVIEW_TOKEN = "WCPreviewToken";
export const NEW_PREVIEW_SESSION = "newPreviewSession";
export const STORE_ID = "STORE_ID";
export const CURRENT_USER = "currentUser";
export const FOR_USER_SESSION = "forUserSession";
export const WINDOW_COUNTER = "windowCounter";
export const WINDOW_ID = "windowId";
export const APPOVAL_TOOL_ID = "approvalManagement";
export const USERS_TOOL_ID = "userManagement";
export const ORG_TOOL_ID = "organizationManagement";
export const ACCOUNT = "ACCOUNT";
export const LANGID = "langId";
export const LOCALE = "locale";
export const URL_LANG_REJECTED = "URL_LANG_REJECTED";
export const SHOW_API_FLOW = "showAPIFlow";
export const PRODUCTION = "production";
export const FOR_USER_ID = "forUserId";
//parent CSR window path when shop on behalf.
export const SHOP_ON_BEHALF_PATH = "/shop-on-behalf";
export const SKIP_WC_TOKEN_HEADER = "skipWCTokenHeader";
export const EXPIRED_PASSWORD_PAGE_ERROR = "expiredPasswordPageError";
export const SHOW_API_FLOW_TRANSACTION = "Transaction";
export const SHOW_API_FLOW_SEARCH = "Search";
export const SELECTED_PROFILE = "selectedProfile";

//SEO & page layout
export const HOME = "home";
export const LINCOLN_HOME = "FordUSLincoln";
export const WEBAPP = "webapp";
export const CART_PAGE = "cart-page";
export const ORDER_CONFIRMATION_PAGE = "order-confirmation-page";
export const CHECKOUT_PAGE = "check-out-page";

export const PAGE_TYPE = {
  CART_PAGE: "CartPage",
  ORDER_CONFIRMATION_PAGE: "OrderConfirmationPage",
  CHECK_OUT_PAGE: "CheckOutPage",
  VARIANT_PAGE: "VariantPage",
  ITEM_PAGE: "ItemPage",
  PRODUCT_PAGE: "ProductPage",
  HOME_PAGE: "HomePage",
  CATEGORY_PAGE: "CategoryPage",
};

export const MANAGED_STATIC_PAGES = [
  PAGE_TYPE.HOME_PAGE,
  PAGE_TYPE.CHECK_OUT_PAGE,
  PAGE_TYPE.CART_PAGE,
  PAGE_TYPE.ORDER_CONFIRMATION_PAGE,
];

export const PRODUCT_TOKEN = "ProductToken";
export const STATIC_PAGES_TOKEN = "StaticPagesToken";

export const CHILD_ROUTE_SEPARATOR = "/";


export const FILTERCATEGORY={
  Thule : "BRAND",
  NOCO : "BRAND",
  ConsoleVault : "BRAND",
  Covercraft : "BRAND",
  Invision : "BRAND",
  REV : "BRAND",
  AMP : "BRAND",
  Curt : "BRAND",
  ECCO : "BRAND",
  Napier : "BRAND",
  Crew : "CAB TYPE",
  SuperCab : "CAB TYPE",
  Black : "COLOR",
  Chrome : "COLOR",
  Espresso : "COLOR",
  Taupe : "COLOR",
  Ebony : "COLOR",
  CharcoalBlack : "COLOR",
  Cargo : "RACK APPLICATION",
  Bike : "RACK APPLICATION",
  WaterSports : "RACK APPLICATION",
  Snowsport : "RACK APPLICATION"
  }

 export const SUBCATEGORYLIST=[
    {subCategory:"Bed Covers",category:"Bed/Cargo Area",url:"/lincoln-bed-covers"},
    {subCategory:"Cargo Area Products",category:"Bed/Cargo Area",url:"/lincoln-cargo-area-products"},
    {subCategory:"Liners and Mats",category:"Bed/Cargo Area",url:"/lincoln-liners-and-mats"},
    {subCategory:"Tents",category:"Bed/Cargo Area",url:"/lincoln-tents"},
    {subCategory:"Audio",category:"Electronics",url:"/lincoln-audio"},
    {subCategory:"Dashcam",category:"Electronics",url:"/lincoln-dashcam"},
    {subCategory:"Keyless Entry",category:"Electronics",url:"/lincoln-keyless-entry"},
    {subCategory:"Lamps, Lights and Treatments",category:"Electronics",url:"/lincoln-lamps-lights-and-treatments"},
    {subCategory:"Parking Assist System",category:"Electronics",url:"/lincoln-parking-assist-system"},
    {subCategory:"Performance",category:"Electronics",url:"/lincoln-performance"},
    {subCategory:"Rear Sear Entertainment",category:"Electronics",url:"/lincoln-rear-seat-entertainment"},
    {subCategory:"Remote Start and Vehicle Security",category:"Electronics",url:"/lincoln-vehicle-security"},
    {subCategory:"Covers,Deflectors, and Protectors",category:"Exterior",url:"/lincoln-covers-and-protectors"},
    {subCategory:"Fuel",category:"Exterior",url:"/lincoln-fuel"},
    {subCategory:"Hitches,Towing and Recovery",category:"Exterior",url:"/lincoln-trailer-towing"},
    {subCategory:"Racks and Carriers",category:"Exterior",url:"/lincoln-racks-and-carriers"},
    {subCategory:"Running Boards, Step Bars and Rock Rails",category:"Exterior",url:"/lincoln-running-boards-and-step-bars"},
    {subCategory:"Splash Guard",category:"Exterior",url:"/lincoln-splash-guards"},
    {subCategory:"Ash or Coin Cup",category:"Interior",url:"/lincoln-ash-or-coin-cup"},
    {subCategory:"Comfort and Convenience",category:"Interior",url:"/lincoln-comfort-convenience"},
    {subCategory:"Floor Mats",category:"Interior",url:"/lincoln-floor-mats"},
    {subCategory:"Safety/Emergency Kits",category:"Interior",url:"/lincoln-safety-emergency-kits"},
    {subCategory:"Seat Covers",category:"Interior",url:"/lincoln-seat-covers"},
    {subCategory:"Locks",category:"Wheels",url:"/lincoln-locks"},
    {subCategory:"Wheels",category:"Wheels",url:"/lincoln-wheels"},
  ]

 export const CATEGORYLIST=[
    {category:"Accessories",url:"/lincoln-all"},
    {category:"Bed/Cargo Area",url:"/bed-cargo-area-products"},
    {category:"Electronics",url:"/lincoln-electronics"},
    {category:"Exterior",url:"/lincoln-exterior"},
    {category:"Interior",url:"/lincoln-interior"},
    {category:"Wheels",url:"/lincoln-wheels"},
   ]