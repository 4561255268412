/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const HOME = "/";
export const OLD_HOME = "/FordUSLincoln";
//Order
export const ACCOUNT = "/account";
export const CART = "/cart";
export const CONTACT = "/contact-us";
export const WARRANTY_INFORMATION = "/warranty-information";
export const CHECKOUT = "/checkout";
export const CHECKOUT_SHIPPING = `${CHECKOUT}/shipping`;
export const CHECKOUT_REWARDS = `${CHECKOUT}/rewards`;
export const CHECKOUT_PAYMENT = `${CHECKOUT}/payment`;

export const ACCOUNT_OLD = `${OLD_HOME}/account`;
export const CART_OLD = `${OLD_HOME}/cart`;
export const CONTACT_OLD = `${OLD_HOME}/contact-us`;
export const WARRANTY_INFORMATION_OLD = `${OLD_HOME}/warranty-information`;
export const CHECKOUT_OLD = `${OLD_HOME}/checkout`;
export const CHECKOUT_SHIPPING_OLD = `${OLD_HOME}${CHECKOUT}/shipping`;
export const CHECKOUT_PAYMENT_OLD = `${OLD_HOME}${CHECKOUT}/payment`;
export const CHECKOUT_INFORMATION_OLD = `${OLD_HOME}${CHECKOUT}/information`;
export const SITEMAP_OLD = `${OLD_HOME}/sitemap`;
export const SEARCH_OLD = `${OLD_HOME}/search`;
export const MAKERETURN_OLD = `${OLD_HOME}/MakeReturn`;
export const RETURNSLIP_OLD = `${OLD_HOME}/ReturnLabelSlip`;
export const LEARNMOREREWARDS_OLD = `${OLD_HOME}/LearnMoreRewards`;
export const ORDER_HISTORY_OLD = `${OLD_HOME}${ACCOUNT}/order-history`;
export const FAQ_OLD = `${OLD_HOME}/Faq`;
export const SHIPPING_DELIVERY_OLD = `${OLD_HOME}/shipping-delivery`;
export const RETURN_OLD = `${OLD_HOME}/return`;
export const TNC_OLD = `${OLD_HOME}/terms-conditions`;
export const ADDRESS_BOOK_OLD = `${OLD_HOME}/account/addresses`;
export const MY_GARAGE_OLD = `${OLD_HOME}${ACCOUNT}/my-garage`;

export const CHECKOUT_AFTER_PAYMENT = `${CHECKOUT}/after-payment`;
export const CHECKOUT_REVIEW = `${CHECKOUT}/review`;
export const ORDER_CONFIRMATION = "/order-confirmation";
export const ORDER_HISTORY = `${ACCOUNT}/order-history`;
export const MY_GARAGE = `${ACCOUNT}/my-garage`;
export const ADD_NEW_VEHICLE = `${ACCOUNT}/my-garage/new`;
export const EDIT_NEW_VEHICLE = `${ACCOUNT}/my-garage/edit/:vehicleId`;
export const WISH_LIST = `${ACCOUNT}/wish-list`;
export const VIEW_WISH_LIST = "/view-wish-list";
// export const WISH_LIST = "/wish-list";
export const RECURRING_ORDERS = "/recurring-orders";
export const INPROGRESS_ORDERS = "/inprogress-orders";
export const REQUISITION_LISTS = "/requisition-lists";
export const ORDER_DETAILS = "/order-details";
export const ORDER_ID_PARAM = "/:orderId";
export const ORDER_DETAILS_ROUTE = `${ORDER_DETAILS}${ORDER_ID_PARAM}`;
export const SITEMAP = "/sitemap";

//New routing for Shipping and its breadcrumb

export const CHECKOUT_INFORMATION = `${CHECKOUT}/information`;

//User
export const ADDRESS_BOOK = "/account/addresses";
export const ADD_ADDRESS = "/add-address";
export const EDIT_ADDRESS = "/edit-address";
export const ADDRESS_ID_PARAM = "/:addressId";
export const EDIT_ADDRESS_ROUTE = `${EDIT_ADDRESS}${ADDRESS_ID_PARAM}`;
export const PERSONAL_INFORMATION = "/personal-information";

//Account
export const SIGNIN = "/sign-in";
export const BUYER_REGISTRATION = "/buyer-registration";
export const ORG_REGISTRATION = "/organization-registration";
export const DASHBOARD = "/dashboard";
export const FORGOT_PASSWORD = "/forgot-password";
//Search
export const SEARCH = "/search";
export const PERSONAL_INFO = `${ACCOUNT}`;

//register user access only
export const REGISTER_PROTECTED = "register";
//only guest and generic user access
export const GENERIC_PROTECTED = "generic";

export const BUYER_MANAGEMENT = "/buyer-management";
export const APPROVALS_MANAGEMENT = "/approvals-management";
export const ORGANIZATION_MANAGEMENT = "/organization-management";
export const ORDER_APPROVAL = "/order-approval";

//Checkout Profile
export const CHECKOUT_PROFILES = "/list-checkout-profiles";
export const CHECKOUT_PROFILE_CREATE = "/checkout-profile-create";
export const CHECKOUT_PROFILE_EDIT = "/edit-checkout-profile";

// in-progress orders + items
export const IP_ORDER_DETAILS = "/inprogress-order-details";
export const IP_ORDER_DETAILS_ROUTE = `${IP_ORDER_DETAILS}${ORDER_ID_PARAM}`;

//Static Pages

export const FAQ = "/Faq";
export const SHIPPING_DELIVERY = "/shipping-delivery";
export const RETURN = "/return";
export const TNC = "/terms-conditions";

export const REDIRECT_URL_AFTER_LOGIN = "/LoginRedirect";
export const REDIRECT_URL_AFTER_LOGOUT = "/LogoutRedirect";
export const MAKERETURN = "/MakeReturn";
export const RETURNSLIP = "/ReturnLabelSlip";
export const LEARNMOREREWARDS = "/LearnMoreRewards";
