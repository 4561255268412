/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { takeLatest } from "redux-saga/effects";
//Redux
import * as ACTIONS from "../../action-types/wishList";
import * as WORKERS from "../workers/wishList";

/**
 * Wish List watch saga
 * watchers to intercept wish list actions
 */
export function* watchSaga() {
  yield takeLatest(
    ACTIONS.WISHLIST_GET_REQUESTED,
    WORKERS.fetchWishListDetails
  );
}
