/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//fetch contract
export const FETCH_MODEL_YEAR_REQUESTED = "FETCH_MODEL_YEAR_REQUESTED";
export const FETCH_MODEL_YEAR_SUCCESS = "FETCH_MODEL_YEAR_SUCCESS";
export const FETCH_MODEL_YEAR_ERROR = "FETCH_MODEL_YEAR_ERROR";
//fetch VEHICLES
export const FETCH_VEHICLES_REQUESTED = "FETCH_VEHICLES_REQUESTED";
export const FETCH_VEHICLES_SUCCESS = "FETCH_VEHICLES_SUCCESS";
export const FETCH_VEHICLES_ERROR = "FETCH_VEHICLES_ERROR";
